import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { FoodTypes } from "../../constants/foodItems.constants";
import { defaultSize } from "../../constants/var";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";

const AddOnItems = ({ section }) => {
  const sectionName = "Ingredient";
  const routeName = "ingredient";
  const params = useParams();

  const { currency } = useContext(AuthContext);

  const api = {
    category: apiPath.common.ingredientCategories,
    ingredient: apiPath.ingredients,
    size: apiPath.common.foodSize,
    importFile: apiPath.importCategory + "/" + params.type,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();

  const [list, setList] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);

  const [deleteModal, showDeleteModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showStatusMessage, setShowStatusMessage] = useState(false);
  const [selected, setSelected] = useState();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const debouncedSearchText = useDebounce(searchText, 300);

  const handleChangeStatus = (id) => {
    request({
      url: api.ingredient + "/" + id + "/status",
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onDelete = (id) => {
    request({
      url: api.ingredient + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const getCategories = () => {
    request({
      url: api.category,
      method: "GET",
      onSuccess: (data) => {
        setCategories(data.data);
      },
    });
  };

  const columns = [
    {
      title: lang("S. No"),
      dataIndex: "index",
      key: "index",
      render: (value, item, index) =>
        pagination.current === 1
          ? index + 1
          : (pagination.current - 1) * 10 + (index + 1),
    },
    {
      title: lang("Ingredient Category Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name}</span> : "-";
      },
    },
    {
      title: lang("Ingredients Category arabic name"),
      dataIndex: "name",
      key: "ar_name",
      render: (_, { ar_name }) => {
        return ar_name ? <span className="cap">{ar_name}</span> : "-";
      },
    },
    {
      title: lang("Price"),
      dataIndex: "price",
      key: "price",
      render: (_, { ingredient_size }) => {
        return ingredient_size ? (
          <div style={{ display: "flex", gap: "20px" }}>
            {ingredient_size.map((item, index) => {
              return (
                <div key={index}>
                  <p>{item.size?.name}</p>
                  <p>{currency + " " + item.price}</p>
                </div>
              );
            })}
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Action"),
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip
              title={lang("Edit")}
              color={"purple"}
              key={"edit" + routeName}
            >
              <Button
                title="Edit"
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record);
                  setVisible(true);
                }}
              >
                <i className="fa fa-light fa-pen"></i>
                <span>{lang("Edit")}</span>
              </Button>
            </Tooltip>

            <Tooltip title={lang("Delete")} color={"purple"} key={"Delete"}>
              <Button
                title={lang("Delete")}
                className="btnStyle deleteDangerbtn"
                onClick={() => {
                  if (record.have_item) {
                    return setShowMessage(true);
                  }
                  setSelected(record);
                  showDeleteModal(true);
                }}
              >
                <img src={deleteWhiteIcon} />
                <span>{lang("Delete")}</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: lang("Status"),
      key: "is_active",
      dataIndex: "is_active",
      render: (_, { _id, is_active, have_active_item }) => {
        return (
          <Switch
            onChange={() => {
              if (have_active_item) {
                return setShowStatusMessage(true);
              }
              handleChangeStatus(_id);
            }}
            checked={is_active}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getCategories();
  }, [refresh, debouncedSearchText]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const categoryId =
      filters && filters?.categoryId ? filters?.categoryId : null;

    request({
      url:
        api.ingredient +
        `?category=${categoryId ? categoryId : ""}&status=${
          filterActive ? filterActive.join(",") : ""
        }&page=${
          pagination && pagination.current ? pagination.current : 1
        }&pageSize=${
          pagination && pagination?.pageSize ? pagination.pageSize : 10
        }&search=${debouncedSearchText}`,
      method: "GET",
      onSuccess: ({data,status,total}) => {
        setLoading(false);
        setList(data);
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: total,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleCategoryChange = (categoryId) => {
    fetchData(pagination, { categoryId: categoryId });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Item Ingredients")}
        extra={
          <>
            <div>
              <Select
                placeholder={lang("All Category")}
                onChange={handleCategoryChange}
              >
                {categories && categories && categories.length > 0
                  ? categories.map((item, index) => (
                      <Select.Option
                        key={item._id}
                        value={item._id}
                        label={item.name}
                      >
                        <span className="cap">{item.name}</span>
                      </Select.Option>
                    ))
                  : null}
              </Select>
            </div>
            <div className="w-100 text-head_right_cont">
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang(" Add Ingredients")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination checkBoxSrNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
          currency={currency}
        />
      )}

      {deleteModal && (
        <DeleteModal
          title={lang("Delete Ingredient")}
          subtitle={lang("Are you sure you want to Delete this Ingredient?")}
          show={deleteModal}
          hide={() => {
            showDeleteModal(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
        />
      )}

      {showMessage && (
        <DeleteModal
          title={lang("Delete Ingredient")}
          subtitle={lang(
            "This ingredient contains food items, please delete food item before deleting ingredient",
          )}
          show={showMessage}
          hide={() => {
            setShowMessage(false);
          }}
          onOk={() => setShowMessage(false)}
        />
      )}

      {showStatusMessage && (
        <DeleteModal
          title={lang("Inactive Ingredient")}
          subtitle={lang(
            "This ingredient contains food items, please inactive food item before inactive ingredient",
          )}
          show={showStatusMessage}
          hide={() => {
            setShowStatusMessage(false);
          }}
          onOk={() => setShowStatusMessage(false)}
        />
      )}
    </>
  );
};

const AddForm = ({ section, api, show, hide, data, refresh, currency }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const { language } = useAppContext();
  const [isChecked, setIsChecked] = useState(data ? data?.contain_size : false);

  const [categories, setCategories] = useState([]);
  const [foodSize, setFoodSize] = useState([]);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    setImage(data);
    data.length > 0 ? setFile(data[0].url) : setFile([]);
  };

  const getCategories = () => {
    request({
      url: api.category,
      method: "GET",
      onSuccess: (data) => {
        setCategories(data.data);
      },
    });
  };

  const getFoodSize = () => {
    request({
      url: api.size,
      method: "GET",
      onSuccess: (data) => {
        setFoodSize(data.data);
      },
    });
  };

  const handleSizeOption = (e) => {
    setIsChecked(e.target.checked);
  };

  const onCreate = (values) => {
    const {
      name,
      ar_name,
      category_id,
      ingredient_size,
      is_active,
      type,
      price,
      contain_size,
    } = values;

    console.log(isChecked, contain_size, price, {
      price,
      size: defaultSize._id,
    });

    const payload = {
      name,
      ar_name,
      category_id:
        data && category_id === data.category_id?.name
          ? data.category_id._id
          : category_id,
      ingredient_size: isChecked
        ? ingredient_size
        : [
            {
              price,
              size: defaultSize._id,
            },
          ],
      is_active,
      type,
      contain_size: isChecked,
    };

    setLoading(true);

    request({
      url: `${data ? api.ingredient + "/" + data._id : api.ingredient}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    console.log(data);
    let price;
    const ingredients = data.ingredient_size.map((item) => {
      return {
        size: item.size?._id ?? item.size,
        price: item.price,
      };
    });

    const updatedIngredients = ingredients.filter((item) => {
      if (item.size?.toString() == defaultSize._id?.toString()) {
        price = item.price;
        return false;
      }
      return true;
    });

    form.setFieldsValue({
      ...data,
      category_id: data?.category_id?._id ?? data?.category_id,
      ingredient_size: updatedIngredients,
      price:
        !data?.contain_size && data?.ingredient_size?.length > 0
          ? data?.ingredient_size[0]?.price
          : null,
    });
    setFile([data.image]);
  }, [data]);

  useEffect(() => {
    getCategories();
    getFoodSize();
  }, []);

  return (
    <Modal
      open={show}
      width={750}
      okText={data ? lang("Save") : lang("Add")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          is_active: true,
          type: FoodTypes.VEG,
        }}
      >
        <h4 className="modal_title_cls">
          {(data ? lang("Edit") : " " + lang("Add New")) +" " + lang("Ingredient")}
        </h4>
        <Row gutter={[16, 0]}>
          <Col span={24} md={24}>
            <Form.Item name="category_id" label={lang("Ingredients Category")}>
              <Select placeholder={lang("Select Ingredients Category")}>
                {categories && categories && categories.length > 0
                  ? categories.map((item, index) => (
                      <Select.Option
                        key={item._id}
                        value={item._id}
                        label={item.name}
                      >
                        <span className="cap">
                          {language !== ("en" || null)
                            ? item[`${language}_name`] ?? item?.name
                            : item?.name}
                        </span>
                      </Select.Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item
              label={lang(`Toppings Name`)}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Name is required"),
                },
                {
                  max: 35,
                  message: lang(
                    "Name should not contain more then 20 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
            </Form.Item>
          </Col>

          <Col span={24} md={12}>
            <Form.Item
              label={lang(`Toppings Name Arabic`)}
              name="ar_name"
              rules={[
                {
                  required: true,
                  message: lang("Arabic Name is required"),
                },
                {
                  max: 35,
                  message: lang(
                    "Name should not contain more then 20 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder="أدخل الاسم" />
            </Form.Item>
          </Col>

          <Col span={24} md={24} className="d-flex mb-2">
            <Form.Item
              className="  mb-0 conter-cotain cc-check-box"
              label={lang(`Contain Size`)}
              name="contain_size"
              valuePropName="checked"
            >
              <Checkbox
                onChange={handleSizeOption}
                defaultChecked={isChecked}
              />
            </Form.Item>
          </Col>
        </Row>

        {isChecked && (
          <Form.List
            name="ingredient_size"
            className="mt-2"
            initialValue={[{ size: "", price: 0 }]}
          >
            {(fields, { add, remove }, { form }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <Space key={field.key} align="baseline" className="gap-cls">
                      <Row>
                        <Col span={24} md={11}>
                          <Form.Item
                            className="me-2 ms-2"
                            {...field}
                            name={[field.name, "size"]}
                            label={lang("Ingredients Size")}
                          >
                            <Select
                              placeholder={lang("Select Ingredients Size")}
                              getPopupContainer={(triggerNode) => triggerNode.parentNode}
                            >
                              {foodSize.map((item, index) => (
                                <Select.Option
                                  key={item._id}
                                  value={item._id}
                                  label={item.name}
                                >
                                  {language !== ("en" || null)
                                    ? item[`${language}_name`] ?? item?.name
                                    : item?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={20} md={11}>
                          <Form.Item
                            className="me-2 ms-2"
                            {...field}
                            name={[field.name, "price"]}
                            label={lang("Price") + " (" + currency + " )"}
                            rules={[
                              {
                                required: true,
                                message: lang("Please Enter Price"),
                              },
                            ]}
                          >
                            <InputNumber />
                          </Form.Item>
                        </Col>
                        <Col span={4} md={2} className="add-menu-items-delete">
                          {index > 0 ? (
                            <div className="minus-wrap " style={{}}>
                              <DeleteOutlined
                                className="delete-circal"
                                onClick={() => remove(field.name)}
                                style={{ borderRadius: "8px" }}
                              />
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </Space>
                  </div>
                ))}
                <Col span={4}>
                  <Form.Item>
                    <Button
                      onClick={() => add()}
                      block
                      className="primary_btn btnStyle"
                    >
                      <i class="fas fa-plus me-1"></i>
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.List>
        )}

        {!isChecked && (
          <Col span={20} md={11}>
            <Form.Item
              className="me-2 ms-2"
              name={"price"}
              label={lang("Price") + " (" + currency + " )"}
              rules={[
                {
                  required: true,
                  message: lang("Please Enter Item Price"),
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        )}

        <Row gutter={24}>
          <Col span={24} sm={24} md={12}>
            <div className="status_wrap">
              <Form.Item
                label={lang("Status")}
                name="is_active"
                className="mb-0"
              >
                <Radio.Group style={{ font: "bold" }}>
                  <Radio value={true}>{lang("Active")}</Radio>
                  <Radio value={false}>{lang("De-Active")}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>

          <Col span={24} sm={24} md={12}>
            <div className="status_wrap">
              <Form.Item label="Type" name="type" className="mb-0 d-flex">
                {/* <div className="start-wraps"> */}
                <Radio.Group style={{ font: "bold" }}>
                  <Radio className="start-01" value={FoodTypes.VEG}>
                    {lang(FoodTypes.VEG)}
                  </Radio>
                  <Radio className="start-01" value={FoodTypes.NONVEG}>
                    {lang(FoodTypes.NONVEG)}
                  </Radio>
                </Radio.Group>
                {/* </div> */}
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddOnItems;
