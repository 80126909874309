import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Paymentimg from "../../assets/images/payments.png";
import Currency from "../../components/Currency";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { Months } from "../../constants/var";
import { useAppContext } from "../../context/AppContext";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import ViewOrderModal from "../../components/ViewOrderModal";
import ViewOrderTransaction from "./_ViewModal";
const Search = Input.Search;
const { TabPane } = Tabs;
const { Title } = Typography;

function Transaction() {
  const [selectedTab, setSelectedTab] = useState("PendingRequests");

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  useEffect(() => {
    const data =
      window.location.href.split("?").length > 1
        ? window.location.href.split("?")[1]
        : "";
    if (data == "wallet-transaction") {
      setSelectedTab("WalletTransaction");
    }
  }, []);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab={lang("Pending Approval")} key={`PendingRequests`}>
                  <PendingApproval selectedTab={selectedTab} />
                </TabPane>

                <TabPane
                  tab={lang("Wallet Transactions")}
                  key={"WalletTransaction"}
                >
                  <WalletTransaction selectedTab={selectedTab} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const PendingApproval = ({ selectedTab }) => {
  const [list, setList] = useState([]);
  const [list1, setList1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();
  const { language } = useAppContext();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    // total: 0,
  });


  const [searchCity, setSearchCity] = useState("");


  const [filter, setFilter] = useState({
    year: undefined,
    month: undefined,
  });

  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);

  useEffect(() => {
    getFilter();
  }, []);

  const getFilter = () => {
    request({
      url: `${apiPath.filters}`,
      method: "GET",
      onSuccess: (res) => {
        const { months, years } = res;
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const fetchData = (pagination, status) => {
    setLoading(true);
    const payload = { ...filter };
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    console.log(queryString, "queryString-----------------");

    request({
      url: "vendor/wallet-withdraw" + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ requests, status }) => {
        if (status) {
          console.log(requests, "requests-----------------");
          setLoading(false);
          setList(requests);
          setPagination((prev) => ({ ...prev }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, "error");
      },
    });
  };

  const fetchData1 = (pagination) => {
    setLoading(true);
    const payload = { ...filter };
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    console.log(queryString, "queryString-----------------");

    request({
      url: "vendor/deposit" + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          console.log(data, "data-----------------");
          setLoading(false);
          setList1(data);
          setPagination((prev) => ({ ...prev }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, "error");
      },
    });
  };

  useEffect(() => {
    fetchData(pagination);
    fetchData1(pagination);
  }, [filter, refresh, selectedTab]);

  const columns = [
    {
      title: lang("Request Date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? dateString(created_at) : "N/A",
    },
    {
      title: lang("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => <Currency price={amount || 0} />,
    },
    {
      title: lang("Reason"),
      dataIndex: "reason",
      key: "reason",
      render: (_, { reason }) => reason || "-",
    },
    {
      title: lang("Status"),
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <Tooltip title={lang("Request Status")} color={"purple"}>
          <Button className="btnStyle btnOutlineDelete">{lang(status)}</Button>
        </Tooltip>
      ),
    },
  ];

  const columns1 = [
    {
      title: lang("Request Date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) =>
        created_at ? dateString(created_at) : "-",
    },
    {
      title: lang("Receipt Number"),
      dataIndex: "receipt_number",
      key: "receipt_number",
      render: (receipt_number) =>
        receipt_number ? receipt_number : "N/A",
    },
    {
      title: lang("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => <Currency price={amount || 0} />,
    },
    {
      title: lang("Payment Mod"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (payment_mod) =>
        payment_mod ? payment_mod : "N/A",
    },
    {
      title: lang("Reason"),
      dataIndex: "reason",
      key: "reason",
      render: (reason) => reason || "N/A",
    },
    {
      title: lang("Status"),
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <Tooltip title={lang("Request Status")} color={"purple"}>
          <Button className="btnStyle btnOutlineDelete">{lang(status)}</Button>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between flex-wrap">
          <h3>{lang("PENDING REQUESTS")}</h3>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Year")}
                showSearch
                value={filter?.year}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={years?.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => onChange("year", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Month")}
                showSearch
                value={filter.month}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={months.map((item) => ({
                  ...item,
                }))}
                onChange={(value) => onChange("month", value)}
              />
            </div>

            <Button
              className="ms-sm-2 mt-xs-2 primary_btn manag-btn"
              onClick={() =>
                setFilter({
                  year: undefined,
                  month: undefined,
                })
              }
              type="primary"
              icon={<UndoOutlined />}
            >
              {lang("Reset")}
            </Button>
          </div>
        </div>
      </div>
      <Card bordered={false} className="criclebox tablespace mb-24">
        <SectionWrapper cardHeading={lang("Withdraw Requests")}>
          <div className="table-responsive customPagination">
            <Table
              loading={loading}
              columns={columns}
              dataSource={list}
              pagination={{
                defaultPageSize: 10,
                responsive: true,
                total: pagination.total,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20", "30", "50"],
              }}
              onChange={(pagination) => fetchData(pagination)}
              className="ant-border-space"
            />
          </div>
        </SectionWrapper>
      </Card>
      <Card bordered={false} className="criclebox tablespace mb-24">
        <SectionWrapper cardHeading={lang("Deposit Requests")}>
          <div className="table-responsive customPagination">
            <Table
              loading={loading}
              columns={columns1}
              dataSource={list1}
              rowKey={(record) => record._id}
              pagination={{
                defaultPageSize: 10,
                responsive: true,
                total: pagination.total,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20", "30", "50"],
              }}
              onChange={(pagination) => fetchData1(pagination)}
              className="ant-border-space"
            />
          </div>
        </SectionWrapper>
      </Card>
    </>
  );
};

const WalletTransaction = ({ selectedTab }) => {
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [deposit, setDeposit] = useState();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [wallet, setWallet] = useState();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selected, setSelected] = useState();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    year: undefined,
    month: undefined,
    payment_mod: undefined
  });

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination);
  };

  const getFilter = () => {
    request({
      url: "/vendor/get-transactions/filters",
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;

        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const fetchData = (pagination) => {
    setLoading(true);
    const payload = { ...filter };
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    console.log("Query String:", queryString);

    request({
      url: "/vendor/get-transactions" + `${queryString ? `?${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data, status, total, message, wallet }) => {
        setLoading(false);
        if (status) {
          if (data) {
            setList(data);
            setWallet(wallet)
            setPagination((prev) => ({
              ...prev,
              current: pagination.current,
              total: total,
            }));
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData(pagination);
    getFilter();
  }, [refresh, selectedTab, filter]);

  const columns = [
    {
      title: lang("Category"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { order_id, type,orderData }) => order_id ? <span> {(type === "Debit" ? `${lang("Order")}-${lang("Platform Commission")}` : `${orderData?.company_id ?`${ lang("Company")} `:''}${lang("Order")}-${lang("Amount")}`)} </span> : type === "Debit" ? lang("Withdraw") : lang("Deposit"),
    },
    {
      title: lang("Order ID"),
      dataIndex: "index",
      key: "index",
      render: (_, { order_id, orderData }) => {
        return (
          <span className="cap" >
            {order_id && orderData.uid ? `#${orderData.uid}` : "-"}
          </span>
        );
      },
    },
    {
      title: lang("Payment Mod"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { orderData, order_id }) => 
        (order_id && orderData) ? (
          <span style={{ textTransform: "uppercase" }}>
            {orderData.payment_mod == "cod" ? lang(orderData.payment_mod) : lang('Online Payment')}
          </span>
        ) : lang("Wire"),
    },
    {
      title: lang("Transaction Date"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { created_at, orderData }) => moment(created_at).format("ll"),
    },
    {
      title: lang("Amount"),
      dataIndex: "amount",
      render: (_, { type, amount }) =>
      (
        type === "Credit" ? (
          <span className="cap">
            <Currency price={"+" + amount} />
          </span>
        ) : (
          <Currency price={"-" + amount} />
        )
      )
    },
    {
      title: lang("View"),
      key: "info",
      render: (_, record) => {
        return (
          <Tag
            title={lang("View")}
            onClick={() => {
              if (record.order_id) {
                setSelected(record.orderData);
                setIsViewOpen(true);
              } else {
                setDeposit(record)
                setOpen(true)
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {lang("View")}
          </Tag>
        );
      },
    },
  ];

  return (
    <>
      <div className="tabled categoryService">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={12} md={12}>
            <Card
              bordered={false}
              className="cap criclebox tablespace"
            >
              <div className="collection_dtl_body for-555">
                <Row gutter={24} style={{ padding: "6px 14px" }}>
                  <div className="revenue-card-box">
                    <Title level={5}>{lang("Wallet")}</Title>
                    <Row className="">
                      <Col span={24} xl={24} md={24} sm={24}>
                        <div className="cls-total received-amt">
                          <div className="sales-img-icon">
                            <img src={Paymentimg} alt="Payment" />
                          </div>
                          <div className="sales-text-outer deposite-main">
                            <div className="wallet-balance-1" style={{ cursor: 'pointer' }}>
                              <h6 style={{ fontWeight: "600" }}>
                                {lang("Wallet balance")}
                              </h6>
                              <h4>
                                <Currency price={wallet ? wallet.balance : 0} />
                              </h4>
                            </div>
                            <div>
                            </div>

                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>
              <div></div>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between flex-wrap">
          <h3>{lang("List of All Transactions")}</h3>
          <div className="d-flex align-items-center gap-3 flex-wrap">
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Year")}
                showSearch
                value={filter?.year}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={years?.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => onChange("year", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Month")}
                showSearch
                value={filter.month}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={months.map((item) => ({
                  ...item,
                }))}
                onChange={(value) => onChange("month", value)}
              />
            </div>

            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Payment Mode")}
                showSearch
                value={filter.payment_mod}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={[{
                  value: 'cod',
                  label: 'COD'
                }, {
                  value: 'online',
                  label: 'ONLINE'
                }, {
                  value: 'wire',
                  label: 'WIRE'
                }]}
                onChange={(value) => onChange("payment_mod", value)}
              />
            </div>
            <Button
              className="ms-sm-2 mt-xs-2 primary_btn manag-btn"
              onClick={() =>
                setFilter({
                  country_id: undefined,
                  city_id: undefined,
                  year: undefined,
                  month: undefined,
                })
              }
              type="primary"
              icon={<UndoOutlined />}
            >
              {lang("Reset")}
            </Button>
          </div>
        </div>
      </div>
      {/* <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4> */}

      <div className="table-responsive customPagination withOutSearilNo">
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            defaultPageSize: 10,
            responsive: true,
            total: pagination.total,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50"],
          }}
          onChange={handleChange}
          className="ant-border-space"
        />
      </div>

      {isViewOpen && selected && (
        <ViewOrderModal
          show={isViewOpen}
          hide={() => {
            setIsViewOpen(false);
            setRefresh(true);
          }}
          data={selected}
          hidePdfBtn={true}
        />
      )}

      {open && deposit && (
        <ViewOrderTransaction
          show={open}
          hide={() => {
            setOpen(false);
            setDeposit()
          }}
          data={deposit}
        />
      )}
      
    </>
  );
};

export default Transaction;
