import lang from "../helper/langHelper";

export const Last20Years = Array.from({ length: 20 }, (_, index) =>
  (new Date().getFullYear() - index).toString(),
);
export const Months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const CancelOrder = [
  "Payment Issues",
  "Unavailability of Items",
  "Delivery Address Inaccuracy",
  "Security or Fraud Concerns",
  "Violation of Platform Policies",
  "Customer Not Accept",
  "Customer Absent",
];

export const defaultSize = {
  _id: "6595594c33a718e860e10342",
  name: "Regular",
  ar_name: "عادي",
};
