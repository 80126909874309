import React, { useState, useEffect } from "react";
import { useAppContext } from '../context/AppContext' 
import { useAuthContext } from "../context/AuthContext";

const getCurrency = JSON.parse(localStorage.getItem("currencySet")) ? JSON.parse(localStorage.getItem("currencySet")) : { value: '1', text: 'AED', label: 'AED' };

const Currency = ({ price }) => { 
    const {currency} = useAuthContext()
    return (
        <>
        {/* {currency}{" "} */}
        {
            ( price * parseFloat(getCurrency.value)).toLocaleString("en-IL", { style: "currency", currency: currency, minimumFractionDigits: 0,currencyDisplay: "code" }) 
            // .toFixed(2)
            }</>
    )
} 

export const CurrencySymbol = ({ price }) => { 
    const {userProfile} = useAuthContext()
    return (
        <>{userProfile.country_id?.currency_symbol}{" "}{(price * parseFloat(getCurrency.value)).toFixed(2)}</>
    )
}

export const currency = (price) => {
    const getCurrency = JSON.parse(localStorage.getItem("currencySet")) ? JSON.parse(localStorage.getItem("currencySet")) : { value: '1', text: 'AED', label: 'AED' };
    return (`${getCurrency.text}  ${(price * parseFloat(getCurrency.value)).toFixed(2)}`)
}

export const CurrencyWithOutLabel = (price) => {
    const getCurrency = JSON.parse(localStorage.getItem("currencySet")) ? JSON.parse(localStorage.getItem("currencySet")) : { value: '1', text: 'AED', label: 'AED' };
    return (`${(price * parseFloat(getCurrency.value)).toFixed(2)}`)
}

export default (Currency);