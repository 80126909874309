import { Card, Col, Row, Tabs } from "antd";
import React, { useState } from "react";

import useRequest from "../../hooks/useRequest";
import { NotificationList } from "./_NotificationList";

const { TabPane } = Tabs;

const notificationTabs = {
  NOTIFICATIONS: "Notifications",
  PUSH: "Push Notifications",
};

function Index() {
  const sectionName = "Notification";
  const routeName = "notification";

  const { request } = useRequest();

  const [selectedTab, setSelectedTab] = useState(
    notificationTabs.NOTIFICATIONS,
  );

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div
                className="notification-main-wrap"
                style={{ padding: "16px" }}
              >
                <NotificationList />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
