export const PRICE = "ZAR";

export const bannerType = [
  { id: "normal", value: "Normal" },
  { id: "category", value: "Category" },
  // {id : 'offer', value : "Offer"}
];

export const Palestine = {
  id: "646b2e0f46865f1f65565346",
  name: "Palestine",
};
