import { MailOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Select, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Plus from "../../assets/images/plus.svg";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddDiscountForm";
import AddAccount from "./AddAccount";
import Currency from "../../components/Currency";
import user from "../../assets/images/face-3.jpg";
import DepsiteModal from "./_DepositModal";
import WithdrawModal from "./_WithdrawModal";
const { TabPane } = Tabs;

const LANGUAGE = [
  { label: "English", key: "en" },
  { label: "Arabic", key: "ar" },
];

export const SETTINGS_TABS = {
  accountAndWallet: "Bank Account / Wallet",
  general: "General",
};

function Index() {
  const sectionName = "Discount";

  const { userProfile } = useContext(AuthContext);
  const { language } = useContext(AppStateContext);

  const api = {
    status: apiPath.statusQuote,
    discount: apiPath.discount,
    revenue: apiPath.revenue,
    addAccount: apiPath.addAccount,
  };

  const [visible, setVisible] = useState(false);
  const { request } = useRequest();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [contactInfo, setContactInfo] = useState({});
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [depositModal, showDepositModal] = useState(false);
  const [withdrawModal, showWithdrawModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState(
    SETTINGS_TABS.accountAndWallet
  );

  const [selectedDiscount, setSelectedDiscount] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  const [userData, setUserData] = useState({});
  const [walletData, setWalletData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});

  let curLang = language || "en";
  let displayName = curLang === "en" ? userData?.name : userData?.ar_name;

  const changeAppLanguage = async (value) => {
    try {
      await request({
        url: apiPath.settings.language,
        method: "PUT",
        data: { language: value },
        onSuccess: (data) => {
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
          }
        },
        onError: (error) => {
          ShowToast(error, Severty.ERROR);
        },
      });

      window.location.reload();
    } catch (error) {
      console.error("Error changing language:", error);
      ShowToast("Error changing language", Severty.ERROR);
    }
  };

  const onUpdateSetting = (values) => {
    console.log(values);
  };

  const fetchData = (pagination, status) => {
    setLoading(true);

    const payload = {};
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize =
      pagination && pagination?.pageSize ? pagination?.pageSize : 10;
    payload.status = selectedTab ? selectedTab : SETTINGS_TABS.accountAndWallet;
    if (selectedTab === SETTINGS_TABS.accountAndWallet) {
    }
  };

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  const fetchContactInfo = () => {
    request({
      url: "/support/contact",
      method: "GET",
      onSuccess: ({ data }) => {
        setContactInfo({
          email: data?.email,
          phone: "+" + data?.country_code + data?.mobile_number,
        });
      },
    });
  };

  const GetWallet = () => {
    request({
      url: "vendor/auth/get-wallet",
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log("walletdata", data);
        if (status) {
          setWalletData(data);
        }
      },
    });
  };

  const getInvoice = () => {
    request({
      url: "vendor/auth/invoice",
      method: "GET",
      onSuccess: ({ data }) => {
        setInvoiceData(data);
        console.log("setInvoiceData", data);
      },
    });
  };

  const fetchBankData = () => {
    request({
      url: "vendor/auth/get-profile",
      method: "GET",
      onSuccess: ({ data }) => {
        setUserData(data);
        console.log("bankData", data);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ current: 1 }, selectedTab);
    if (selectedTab === SETTINGS_TABS.general) {
      fetchContactInfo();
    }
    GetWallet();
    getInvoice();
    fetchBankData();
  }, [refresh, selectedTab]);

  useEffect(() => {
    if (!!userProfile && userProfile.user_language) {
      form.setFieldValue("user_language", userProfile.user_language);
    }

    // fetchBankData();
  }, [userProfile]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get("tab");
    switch (path) {
      case SETTINGS_TABS.accountAndWallet:
        return setSelectedTab(SETTINGS_TABS.accountAndWallet);
      default:
        break;
    }
  }, []);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane
                  tab={lang(SETTINGS_TABS.accountAndWallet)}
                  key={SETTINGS_TABS.accountAndWallet}
                >
                  <div className="wlt-setting" style={{ position: "relative" }}>
                    <h5 className="wlt-typography">{lang("Wallet")}</h5>
                    <Row gutter={[24,24]}>
                      <Col xs={24} xl={12} className="sm-padding-0">
                        <div className="wlt-section">
                          <div className="wlt-text">
                            <h6>{lang("My Wallet")}</h6>
                            <p>
                              {lang("Balance")} :{" "}
                              <span>
                                <Currency price={walletData?.balance ?? 0} />
                              </span>
                            </p>
                          </div>
                          <div className="wlt-btn-sec">
                          {walletData?.balance > 0 ? (
                            <div>
                              <Button
                                onClick={() => {
                                  showWithdrawModal(true);
                                }}
                                className="primary_btn btnStyle"
                              >
                                {lang("Withdraw money")}
                              </Button>
                            </div>
                          ) : null}

                          <div>
                            <Button
                              onClick={() => {
                                showDepositModal(true);
                              }}
                              className="primary_btn btnStyle"
                            >
                              {lang(" Add money")}
                            </Button>
                          </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} xl={12} className="sm-padding-0">
                        <div
                          className="wlt-section"
                          
                        >
                          <div className="wlt-text">
                            <p>للتحويل الى حساب المنصة على الحساب التالي:</p>
                            <h6>البنك العربي</h6>
                            <h5>
                              Bright Minds Company for Technology and Logistics
                              Services
                            </h5>
                            <p>شركة برايت مايندز للخدمات التقنية واللوجستية</p>
                            <h6>IBAN: PS48ARAB000000009020355541570</h6>
                            <h6>Swift Code: ARABJOAXXXX</h6>
                            <h6>Currency: NIS</h6>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {!userData?.bank_details?.acc_number && (
                      <div className="mt-3">
                        <Button
                          className="primary_btn btnStyle"
                          onClick={() => {
                            setShowAccountModal(true);
                            setSelectedAccount(null);
                          }}
                        >
                          <span className="add-Ic">
                            <img src={Plus} />
                          </span>
                          {lang("Add Bank Account")}
                        </Button>
                      </div>
                    )}
                    
                    <Row gutter={24}>
                      <Col
                        xs={24}
                        md={24}
                        xl={24}
                        xxl={14}
                        className="sm-padding-0"
                      >
                        <div className="restorent-setting">
                          <h5 className="headding-wlt">
                            {" "}
                            {lang("Bank Account")}
                          </h5>
                          {userData?.bank_details?.acc_number && (
                            <div className=" ">
                              <Button
                                className="primary_btn btnStyle"
                                onClick={() => {
                                  setShowAccountModal(true);
                                  setSelectedAccount(userData);
                                }}
                              >
                                <span className="add-Ic">
                                  <img src={Plus} />
                                </span>
                                {lang("Edit")}
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className="wlt-bank-details">
                          <div className="account-user-img">
                            <img src={userData?.restaurant_id?.logo}></img>
                          </div>
                          <div className="bank-text-details">
                            <div className="bank-text-box">
                              <p>{lang("Name")} :</p>
                              {/* <span>Marci Fumons</span> */}
                              <span>{displayName}</span>
                            </div>
                            <div className="bank-text-box">
                              <p>
                                {" "}
                                {userData?.bank_details?.bank_name &&
                                  `${lang("Bank")} :`}{" "}
                              </p>

                              <span>{userData?.bank_details?.bank_name}</span>
                            </div>
                            <div className="bank-text-box">
                              <p>
                                {userData?.bank_details?.acc_number &&
                                  `${lang("Account")} :`}{" "}
                              </p>

                              <span>{userData?.bank_details?.acc_number}</span>
                            </div>
                            <div className="bank-text-box">
                              <p>
                                {" "}
                                {userData?.bank_details?.beneficiary_name &&
                                  `${lang("Beneficiary")} :`}
                              </p>

                              <span>
                                {userData?.bank_details?.beneficiary_name}
                              </span>
                            </div>
                            <div className="bank-text-box">
                              <p>
                                {" "}
                                {userData?.bank_details?.iban_number &&
                                  `${lang("Iban")} :`}{" "}
                              </p>
                              <span>{userData?.bank_details?.iban_number}</span>
                            </div>
                          </div>
                        </div>
                      </Col>
                     
                    </Row>
                  </div>
                </TabPane>

                <TabPane
                  tab={lang(SETTINGS_TABS.general)}
                  key={SETTINGS_TABS.general}
                >
                  <div className="wlt-setting">
                    <Form
                      id="create"
                      form={form}
                      onFinish={onUpdateSetting}
                      layout="vertical"
                    >
                      <Row gutter={24}>
                        <Col xs={24} md={24} xl={24} xxl={12}>
                          <div className="language-box-type">
                            <h5 className="language-headding">
                              {lang(" Change Language")}
                            </h5>
                            <Form.Item
                              className="mb-0  qty-cls"
                              label={lang("Choose Language")}
                              name="user_language"
                              rules={[
                                {
                                  required: true,
                                  message: lang("Please select the language!"),
                                },
                              ]}
                            >
                              <Select
                                filterOption={(input, option) =>
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                autoComplete="off"
                                placeholder={lang("Select Language")}
                                onChange={(value) => changeAppLanguage(value)}
                              >
                                {LANGUAGE.map((item) => (
                                  <Select.Option
                                    key={item.key}
                                    label={item.label}
                                    value={item.key}
                                  >
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Form>

                    <Row gutter={24}>
                      <Col xs={24} md={24} xl={24} xxl={12}>
                        <div className="language-box-type">
                          <h5 className="language-headding">
                            {lang("Support Center")}
                          </h5>
                          <div className="support-help-text">
                            <Link
                              className="social-media-sec"
                              to={`https://wa.me/${contactInfo?.phone}`}
                              target="_blank"
                            >
                              <WhatsAppOutlined />
                              <p>{lang("Contact Us")}</p>
                            </Link>
                            <Link
                              className="social-media-sec"
                              to={`mailto:${contactInfo?.email}`}
                            >
                              <MailOutlined />
                              <p>{lang("Email Us")}</p>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={24} md={24} xl={24} xxl={12}>
                        <div className="language-box-type">
                          <h5 className="language-headding">
                            {lang("Version")}
                          </h5>
                          <div className="support-help-text">
                            <p>{lang("Version 2.8.1")}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
        {visible && (
          <AddForm
            section={sectionName}
            api={api}
            show={visible}
            hide={() => {
              setSelectedDiscount();
              setVisible(false);
            }}
            data={selectedDiscount}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}

        {showAccountModal && (
          <AddAccount
            section={lang("Edit Bank Account")}
            api={api}
            show={showAccountModal}
            hide={() => {
              setShowAccountModal(false);
              setSelectedAccount();
            }}
            data={selectedAccount}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}

        {depositModal && (
          <DepsiteModal
            show={depositModal}
            hide={() => showDepositModal(false)}
            data={walletData}
          />
        )}

        {withdrawModal && (
          <WithdrawModal
            show={withdrawModal}
            hide={() => showWithdrawModal(false)}
          />
        )}
      </div>
    </>
  );
}

export default Index;
