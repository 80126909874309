import {
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  DatePicker,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";

import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import "react-phone-input-2/lib/style.css";
import { useAppContext } from "../../context/AppContext";
import apiPath from "../../constants/apiPath";
import { removeNullValues } from "../../helper/functions";
import lang from "../../helper/langHelper";
const dateFormat = "YYYY/MM/DD";

const AddForm = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { country: headerCountry } = useAppContext();
  console.log("data1", data);

  useEffect(() => {
    if (!headerCountry.country_id) return;
  }, [headerCountry.country_id]);

  useEffect(() => {
    if (!data) return;
    console.log(data);
    const { acc_number, bank_name, beneficiary_name, iban_number } =
      data?.bank_details;
    form.setFieldsValue({
      ...data,
      acc_number,
      bank_name,
      beneficiary_name,
      iban_number,
    });
  }, [data]);

  const onCreate = (values) => {
    const { acc_number, bank_name, beneficiary_name, iban_number } = values;
    console.log(values, "values");

    let payload = {
      country_id: headerCountry.country_id,
      acc_number,
      bank_name,
      beneficiary_name,
      iban_number,
    };
    setLoading(true);

    // payload = removeNullValues(payload);
    console.log(payload, "hfdjhjkhgjkfhgjkfhg");
    request({
      url: `${api.addAccount}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const age = Form.useWatch("name", { form, preserve: true });
  console.log(age);

  return (
    <Modal
      open={show}
      width={800}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <h4 className="modal_title_cls">{`${
        data ? lang("Edit Bank Account") : lang("Add Bank Account")
      }`}</h4>
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={lang(`Bank Account Number`)}
              name="acc_number"
              rules={[
                {
                  required: true,
                  message: lang("Bank account number is required!"),
                },
                {
                  max: 20,
                  message: lang(
                    "Bank account should not contain more then 20 characters!",
                  ),
                },
                {
                  min: 3,
                  message: lang(
                    "Bank account should contain at least 3 characters!",
                  ),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`7653-XXXX-XXXX-XXXX`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={lang(`Beneficiary Name`)}
              name="beneficiary_name"
              rules={[
                {
                  required: true,
                  message: lang("Beneficiary name is required!"),
                },
                {
                  max: 100,
                  message: lang(
                    "Name should not contain more then 100 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`C. Chris`)} />
            </Form.Item>
          </Col>
          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={lang(`Bank Name`)}
              name="bank_name"
              rules={[
                {
                  required: true,
                  message: lang("Bank name is required!"),
                },
                {
                  max: 100,
                  message: lang(
                    "Name should not contain more then 100 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Bank Name`)} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              className="mb-0"
              label={lang(`IBAN Number`)}
              name="iban_number"
              rules={[
                {
                  required: true,
                  message: lang("IBAN number is required!"),
                },
                {
                  max: 34,
                  message: lang(
                    "IBAN should not contain more then 34 characters!",
                  ),
                },
                {
                  min: 20,
                  message: lang("IBAN should contain at least 20 characters!"),
                },
              ]}
              normalize={(value) => value.trim()}
            >
              <Input
                minLength={20}
                maxLength={34}
                autoComplete="off"
                placeholder={lang(`Enter IBAN Number`)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
