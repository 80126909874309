import { Col, Form, Input, Select, InputNumber } from "antd";
import PhoneInput from "react-phone-input-2";
import { useAppContext } from "../context/AppContext";
import { useState } from "react";
import lang from "../helper/langHelper";

export const TextInputBox = ({
  label,
  name,
  placeholder,
  rules,
  cover,
  className,
  isDisable,
  inputProps,
  colProps,
  ...props
}) => {
  return (
    <Col span={24} md={cover ? cover.md : 12} sm={24} {...colProps} >
      <Form.Item
        className={!!className ? className : ""}
        label={label}
        name={name}
        rules={rules}
        normalize={(value) => value.trimStart()}
        {...props}
      // {...props}
      >
        <Input placeholder={placeholder} disabled={isDisable} {...inputProps} />
      </Form.Item>
    </Col>
  );
};

export const SelectInput = ({
  label,
  name,
  placeholder,
  options,
  rules,
  cover,
  className,
  defaultValue,
  handleChange,
  colProps,
  ...props
}) => {

  const { language } = useAppContext();
  return (
    <Col md={cover ? cover.md : 12} {...colProps} span={24}>
      <Form.Item name={name} label={label} rules={rules}>
        <Select
          {...props}
          placeholder={placeholder}
          className={!!className ? className : ""}
          defaultValue={defaultValue}
          onChange={handleChange}
        >
          {options && options && options.length > 0
            ? options.map((item, index) => (
              <Select.Option
                key={item._id}
                value={item._id}
                label={language !== ("en" || null)
                  ? item[`${language}_name`] ?? item?.name
                  : item?.name}
                onChange={handleChange}
              >
                <span className="cap">

                  {language !== ("en" || null)
                    ? item[`${language}_name`] ?? item?.name
                    : item?.name}

                </span>
              </Select.Option >
            ))
            : null}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const MultiSelect = ({
  cover,
  name,
  label,
  rules,
  placeholder,
  className,
  options,
  colProps,
}) => {
  const { language } = useAppContext();
  return (
    <Col md={cover ? cover.md : 12}{...colProps}>
      <Form.Item name={name} label={label} rules={rules}>
        <Select
          placeholder={placeholder}
          className={!!className ? className : ""}
          mode="multiple" // Set mode to "multiple" for selecting multiple values
        >
          {options && options.length > 0
            ? options.map((item, index) => (
              <Select.Option key={item._id} value={item._id}>
                <span className="cap">
                  {language !== ("en" || null)
                    ? item[`${language}_name`] ?? item?.name
                    : item?.name}
                </span>
              </Select.Option>
            ))
            : null}
        </Select>
      </Form.Item>
    </Col>
  );
};

export const EmailField = ({ label, name, placeholder, cover, className }) => {
  return (
    <Col md={cover ? cover.md : 12}>
      <Form.Item
        className="mb-0"
        label={label}
        name={name}
        rules={[
          { type: "email", message: "The email is not a valid email!" },
          { required: true, message: "Please enter the email!" },
          {
            max: 50,
            message: "Email should not contain more then 50 characters!",
          },
          {
            min: 5,
            message: "Email should contain at least 5 characters!",
          },
          {
            pattern: new RegExp(
              /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
            ),
            message: "Enter valid email!",
          },
        ]}
      >
        <Input autoComplete="off" placeholder={placeholder} />
      </Form.Item>
    </Col>
  );
};

export const PhoneNumberField = ({
  label,
  name,
  placeholder,
  cover,
  className,
  onChange,
  inputProps,
  colProps,
  rules =[],
  isValidCheck =true
}) => {

  const [length, setLength] = useState(20);
  const [isValidNumber, setValidNumber] = useState(true);


  const maxLengthByCountry = {
    il: 16,
    ps: 16,
    default: 17
  };


  const isValid = (value, country) => {
    const countryCode = country.iso2;
    const number = value.slice(country?.dialCode?.length)
    setValidNumber(false)
    if (countryCode === 'il') {
      if (number && !/^5/.test(number)) return lang("Number must start with number 5.");
      //if (number && !/^05/.test(number))  return lang("Number must start with number 05.")
      if (number && !/^972\d{9}$/.test(value)) return lang("Number must be 9 digits.");

      //if (number && !/^972\d{10}$/.test(value))  return lang("Number must be 10 digits.")
    }
    if (countryCode === 'ps') {
      if (number && !/^5/.test(number)) return lang("Number must start with number 5.");
      if (number && !/^970\d{9}$/.test(value)) return lang("Number must be 9 digits.");
      //if (number && !/^970\d{10}$/.test(value)) return lang("Number must be 10 digits.")
    }

    // if (!/^\d+$/.test(value)) {
    //   return "Invalid value: " + value;
    // } 
    setValidNumber(true)
    return true;
  };


  const onHandle = (value, data, type) => {
    const countryCode = data?.countryCode || "default";
    const maxLength = maxLengthByCountry[countryCode] || maxLengthByCountry.default;
    setLength(maxLength)
    if (onChange) {
      onChange(value, data, type)
    }
  };

  return (
    <Col md={cover ? cover.md : 12}{...colProps}>
      <Form.Item
        className="mb-0 phone-input"
        label={<span>{label} <span style={{ fontSize: 12, color: 'gray' }}>({lang("Start with 5")}) </span></span>}
        name={name}
        rules={[...rules,
        ({ getFieldValue }) => ({
          validator(_, value) {
            console.log(value,"getFieldValue");
            if ((!value || isValidNumber !== true)) {
              if(value.length >3 && isValidCheck){
                return Promise.reject(
                  new Error(
                    lang(""),
                  ),
                );
              }
            }
            return Promise.resolve();
          },
        }),
        ]}
      >
        <PhoneInput
          inputProps={{
            ...inputProps,
            name: name,
            required: true,
            autoFocus: false,
            placeholder: placeholder,
            maxLength: length
          }}
          isValid={isValid}
          country={"il"}
          preferredCountries={["ps", "il"]}
          countryCodeEditable={false}
          // disableCountryCode={true}
          onChange={onHandle}
        />
      </Form.Item>
    </Col>
  );
};

export const NumberInputBox = ({
  label,
  name,
  placeholder,
  rules,
  cover,
  className,
  colProps,
  ...props
}) => {
  return (
    <Col md={cover ? cover.md : 12}{...colProps}>
      <Form.Item
        className={!!className ? className : ""}
        label={label}
        name={name}
        rules={rules}
        {...props}
      >
        <InputNumber placeholder={placeholder} />
      </Form.Item>
    </Col>
  );
};
