import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const numberOfDay = (month) => {
  let currentDate = new Date();
  let currentMonth = month ?? 1;
  let daysInMonth = new Date(currentDate.getFullYear(), currentMonth, 0).getDate();
  return daysInMonth
}

const LineChart = ({ current, prev }) => {


  console.log(current, prev, "line");

  let daysArrayCurrent = []
  let daysArrayPrev = []

  if (current.length) {
    daysArrayCurrent = Array.from({ length: numberOfDay(2) }, (_, index) => index + 1)
    current.forEach(item => {
      let dateParts = item._id.split("-");
      let day = parseInt(dateParts[2], 10);
      console.log(day, "DAy");
      daysArrayCurrent[day - 1] = item.total_restaurant_amount;
    });
  }

  if (prev.length) {
    daysArrayPrev = Array.from({ length: numberOfDay(1) }, (_, index) => index + 1)
    prev.forEach(item => {
      let dateParts = item._id.split("-");
      let day = parseInt(dateParts[2], 10);

      daysArrayPrev[day - 1] = item.total_restaurant_amount;
    });
  }


  const data = {
    labels: Array.from({ length: numberOfDay(2) }, (_, index) => index + 1),
    datasets: [
      {
        label: 'Previous Month',
        data: daysArrayPrev,
        borderColor: '#F3E008',
        tension: 0.1,
      },
      {
        label: 'Current Month',
        data: daysArrayCurrent,
        borderColor: '#383B42',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        // yAxisID: 'y1',
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    // plugins: {
    //   legend: {
    //     display: false, // Hide the dataset label
    //   },
    // },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
    },
  };

  return (
    <div>
      <Line
        data={data}
        options={options}
        className="responsive-mobile-lineChart" 
      />
    </div>
  )
}

export default LineChart