import { io } from 'socket.io-client';
import apiPath from './constants/apiPath';
const serverUri = 'https://dev.backend.tawasi.online';
// const serverUri = 'http://54.153.176.44:5500';
// const serverUri = 'http://localhost:5500';
// https://prod-api.footytictactoe.com
const TOKEN = 'ddafffasdassdwerrrtqwer8';

const socket = (token) =>
  io(apiPath.socketUrl, {
    auth: {
      token: TOKEN,
    },
    query: {
      token
    },
  });

export default socket;
