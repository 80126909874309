import { Form, Modal } from "antd";
import { useState } from "react";
import lang from "../helper/langHelper";

const DeleteModal = ({ show, hide, onOk, title, subtitle }) => {
  const [value, setValue] = useState(1);

  return (
    <Modal
      width={700}
      open={show}
      okText={lang("ok")}
      cancelText={lang("Cancel")}
      onOk={() => {
        if (onOk) onOk();
        hide();
      }}
      onCancel={hide}
      centered
      className="tab_modal deleteWarningModal"
    >
      <Form layout="vertical" className="p-2">
        <h4 className="modal_title_cls mb-2">{title}</h4>
        <h4 className="modal_sub_title_cls mb-2">{subtitle}</h4>
      </Form>
    </Modal>
  );
};

export default DeleteModal;
