export const OrderStatus = {
  SCHEDULED:'Scheduled',
  CONFIRM_SCHEDULED:'scheduled confirmed',
  ACCEPT: "accepted",
  PENDING: "pending",
  PROCESSING: "processing",
  READY: "ready to pickup",
  PICKUP: "picked up",
  DELIVER: "delivered",
  CANCEL: "cancelled",
};

export const DriverOrderStatus = {
  CANCEL: "cancelled",
  PENDING: "pending",
  ACCEPT: "confirmed",
  ARRIVED: "arrived at restaurant",
  PICKUP: "picked up",
  DROP: "arrived at drop location",
  OUT: "out for delivery",
  DELIVERED: "delivered",
};
