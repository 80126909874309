import { Col, Input, Row, Tabs, Typography } from "antd";
import React, { useEffect } from "react";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import AdCard from "./_AdCard";
import DeliveryCard from "./_DeliveryCard";
import DiscountCard from "./_DiscountCard";
import OrderCard from "./_OrderCard";
import RevenueCard from "./_RevenueCard";
import SalesCard from "./_SalesCard";
import SalesProfitChart from "./_SalesProfitChart";
import WalletCard from "./_WalletCard";
const Search = Input.Search;
const { TabPane } = Tabs;
const { Title, Text } = Typography;

export const QuoteStatus = {
  REQUEST: "request",
  RECEIVED: "received",
  COMPLETE: "complete",
  FULLFILL: "fulfill",
  ADDONS: "addons",
  ITEMDEALS: "itemdeals",
};

function Index() {
  const api = {
    status: apiPath.statusQuote,
    list: apiPath.listQuote,
  };

  const { request } = useRequest();

  const getBrandList = () => {
    request({
      url: apiPath.brandList,
      method: "GET",
      onSuccess: (data) => {},
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <div className="tabled quoteManagement">
        {/* <Row gutter={[24, 0]}>
          <Col xs={24} xl={12} className="mb-24">
            <OrderCard />
          </Col>
          <Col xs={24} xl={12} className="mb-24">
            <DeliveryCard />
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <RevenueCard />
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <SalesCard />
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <SalesProfitChart />
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <WalletCard />
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <DiscountCard />
          </Col>

          <Col xs={24} xl={12} className="mb-24">
            <AdCard />
          </Col>
        </Row> */}
      </div>
    </>
  );
}

export default Index;
