import React, { Suspense, useContext } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";

import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import { ToastContainer } from "react-toastify";
import Loader from "./components/Loader";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import Error from "./pages/Error";
import Revenue from "./pages/RevenueItems/Revenue";

import {
  Discount,
  Driver,
  Finance,
  History,
  Menu,
  Notification,
  Order,
  Ratings,
  Register,
  Settings,
} from "./pages";
import Terms from "./pages/Auth/TermsAndCondition";
import InvoiceView from "./pages/Finance/Invoice";
import DriverRequest from "./pages/DriverRequest";
import CompanyPending from "./pages/company";
import Transaction from "./pages/Transaction/Index";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ScrollToTop />
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/sign-up" element={<Register />} />
      <Route path="/terms-and-conditions" element={<Terms />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/dashboard" element={<Home />} />

        {/* order route */}
        <Route exact path="/order" element={<Order />} /> 
        <Route exact path="/order/:id/company/:break_slot_time" element={<CompanyPending />} />

        {/* history */}
        <Route exact path="/history" element={<History />} />

        {/* driver */}
        <Route exact path="/drivers" element={<Driver />} />

        {/* menu */}
        <Route exact path="/menu" element={<Menu />} />

        {/* discount */}
        <Route exact path="/discount" element={<Discount />} />

        {/* review */}
        <Route exact path="/review" element={<Ratings />} />

        {/* revenue */}
        <Route exact path="/Revenue" element={<Revenue />} />

        <Route exact path="/driver-request" element={<DriverRequest />} />

        {/* invoice */}
        <Route exact path="/invoice" element={<Finance />} />
        <Route exact path="/invoice/:id" element={<InvoiceView />} />
        <Route exact path="/transactions" element={<Transaction />} />

        {/* notifications */}
        <Route exact path="/notifications" element={<Notification />} />

        {/* settings */}
        <Route exact path="/settings" element={<Settings />} />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
