import { Col, Form, Image, Input, InputNumber, Modal, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";

import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";

const AddCategoryForm = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const handleImage = (data) => {
    data.length > 0 ? setImage(data[0].url) : setImage();
  };

  const onCreate = (values) => {
    const { name, ar_name, is_active, sort } = values;
    const payload = { sort };
    setLoading(true);
    payload.name = name;
    payload.ar_name = ar_name;
    payload.is_active = is_active;

    if (image.length > 0) {
      payload.image = image;
    }

    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ ...data });
    setImage(data.image);
  }, [data]);

  return (
    <Modal
      open={show}
      width={750}
      // title={`${data ? "Update " + section : "Create a New " + section}`}
      okText={data ? lang("Update") : lang("Add")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          is_active: true,
        }}
      >
        <h4 className="modal_title_cls">
          {data ? lang("Edit Category") : lang("Add New Category")}
        </h4>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Category Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Name is required"),
                },
                {
                  max: 100,
                  message: lang(
                    "Name should not contain more then 100 characters!",
                  ),
                },
                {
                  min: 3,
                  message: lang("Name should contain at least 3 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Category Name")}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Category Name Arabic")}
              name="ar_name"
              rules={[
                {
                  required: true,
                  message: lang("Arabic Name is required"),
                },
                {
                  max: 100,
                  message: lang(
                    "Arabic Name should not contain more then 100 characters!",
                  ),
                },
                {
                  min: 3,
                  message: lang(
                    "Arabic Name should contain at least 30 characters!",
                  ),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={`أدخل اسم الفئة`} />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={lang("Category Sort Order")}
              name="sort"
              rules={[
                {
                  required: true,
                  message: lang("Category Sort Order is required"),
                },
              ]}
              // normalize={(value) => value.trimStart()}
            >
              <InputNumber
                maxLength={3}
                autoComplete="off"
                placeholder={lang(`Enter Category Sort Order`)}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={24}>
            <div className="status_wrap">
              <Form.Item label={lang("Status")} name="is_active">
                <Radio.Group>
                  <Radio value={true}>{lang("Active")}</Radio>
                  <Radio value={false}>{lang("De-Active")}</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>

          <Col span={24} md={12}>
            <Form.Item
              className="upload_wrap"
              rules={[
                {
                  validator: (_, value) => {
                    if (image) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(lang("Image is required")));
                  },
                },
              ]}
              label={lang("Upload Image")}
              name="image"
            >
              <SingleImageUpload
                value={image}
                fileType={FileType}
                imageType={"category"}
                onChange={(data) => handleImage(data)}
                isDimension={true}
              />
              <p className="img-size-details">
                {lang(
                  "**Images should be 720x480 for best view in gallery image. You can select only (.gif, .png, .jpeg, .jpg) format files upto 1 MB file size..!!!",
                )}
              </p>
            </Form.Item>
            {
              <div className="mt-2">
                <Image width={120} src={image ? image : notfound}></Image>
              </div>
            }
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddCategoryForm;
