import { Card, Col, Image, Row, Select, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import notfound from "../../assets/images/not_found.png";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { Last20Years, Months } from "../../constants/var";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { lang } from "../../helper/langHelper";
import AddForm from "./AddForm";

const { TabPane } = Tabs;

export const QuoteStatus = {
  REQUEST: "request",
  RECEIVED: "received",
  COMPLETE: "complete",
  FULLFILL: "fulfill",
  ADDONS: "addons",
  ITEMDEALS: "itemdeals",
};

function Driver() {
  const sectionName = "Driver";
  const routeName = "drivers";
  const params = useParams();

  const api = {
    status: apiPath.driver,
    addEdit: apiPath.driver,
    list: apiPath.driver,
    importFile: apiPath.driver + "/" + params.type,
  };

  const [searchText, setSearchText] = useState("");

  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [showStatus, setShowStatus] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [page, setPage] = useState(1);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    getCountry();
  }, []);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        // setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id, search, page) => {
    if (!id) return;
    request({
      url: `/country-city/${id}?page=${page}&pageSize=30${
        search ? `&search=${search}` : ""
      }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          search
            ? setCities((prev) => [...data])
            : setCities((prev) => [...prev, ...data]);
        }
      },
    });
  };

  const handleChangeStatus = (id) => {
    request({
      url: api.list + "/" + id + "/status",
      method: "PUT",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: lang("Driver ID"),
      dataIndex: "index",
      key: "index",
      width: 200,
      render: (_, { uid }) =>
        uid ? <span className="cap">{"#" + uid}</span> : "-",
    },
    {
      title: lang("Image"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, { image }) => (
        <>
          <Image
            width={40}
            height={40}
            src={image ? image : notfound}
            className="table-img"
          />
        </>
      ),
    },
    {
      title: lang("Driver Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name}</span> : "-";
      },
    },
    {
      title: lang("Phone Number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { mobile_number, country_code }) => {
        return mobile_number && country_code ? (
          <span className="cap">
            {"+" + country_code + " " + mobile_number}
          </span>
        ) : (
          "-"
        );
      },
    },
  ];

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, debouncedSearchText, filter]);

  useEffect(() => {
    if (!filter?.country_id) return;
    getCities(filter.country_id, debouncedSearchCity, page);
  }, [page]);

  useEffect(() => {
    if (!filter?.country_id) return;
    getCities(filter.country_id, debouncedSearchCity, 1);
  }, [debouncedSearchCity]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${pagination.pageSize ?? 10}&search=${debouncedSearchText}${
          queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <SectionWrapper
                cardHeading={lang("assignedDrivers")}
                extra={
                  <>
                    {/* <div className="button_group justify-content-end w-100"> */}
                    <div className="w-100 text-head_right_cont">
                      <div className="role-wrap">
                        <Select
                          width="110px"
                          placeholder={lang("year")}
                          showSearch
                          value={filter.year}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={years.map((item) => ({
                            value: item,
                            label: item,
                          }))}
                          onChange={(value) => onChange("year", value)}
                        />
                      </div>
                      <div className="role-wrap">
                        <Select
                          width="110px"
                          placeholder={lang("month")}
                          showSearch
                          value={filter.month}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          options={months.map((item) => ({
                            ...item,
                          }))}
                          onChange={(value) => onChange("month", value)}
                        />
                      </div>
                    </div>
                  </>
                }
              >
                <div className="table-responsive customPagination">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                      defaultPageSize: 10,
                      responsive: true,
                      total: pagination.total,
                      showSizeChanger: true,
                      showQuickJumper: true,
                      pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    onChange={handleChange}
                    className="ant-border-space"
                  />
                </div>
              </SectionWrapper>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${selected?.is_active ? `Suspend` : `Reactive`} Drive`}
          subtitle={`Are you sure you want to ${
            selected?.is_active ? `suspend` : `reactivate`
          } this user?`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={() => handleChangeStatus(selected?._id)}
        />
      )}
    </>
  );
}

export default Driver;
