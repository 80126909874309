import moment from "moment";

export const convertTime = (utcDateTimeString) => {
  const utcDateTime = new Date(utcDateTimeString);

  // Get the user's locale or use a default if unavailable
  const userLocale = navigator.language || "en-US";

  // Get the user's time zone or use a default if unavailable
  const userTimeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";

  console.log(userTimeZone, "djkhjkghjh");

  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

  // Format the date and time according to the user's locale and options
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: userTimeZone,
  };

  try {
    const formattedLocalDateTime = utcDateTime.toLocaleString(
      userLocale,
      options,
    );
    console.log(formattedLocalDateTime);
    return formattedLocalDateTime;
  } catch (error) {
    console.error("Error formatting date:", error.message);
    return moment(utcDateTimeString).format("LLL");
  }
};
