import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
} from "antd";
import * as _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import signinLogo from "../../assets/images/Logo.png";
import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../User/LocationMap";
import Otp from "./Otp";
import { Palestine } from "../../constants/conts";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";
import checkNumber from "../../helper/apis";

const { Content } = Layout;

const vendor = "Vendor";

const dummyDeliveryType = [
  { name: "Delivery", _id: "Delivery" },
  { name: "Pickup", _id: "Pickup" },
];

const weekdays = [
  { name: "sunday", label: "S" },
  { name: "monday", label: "M" },
  { name: "tuesday", label: "T" },
  { name: "wednesday", label: "W" },
  { name: "thursday", label: "Th" },
  { name: "friday", label: "F" },
  { name: "saturday", label: "ST" },
];

const FileType = ["image/png", "image/jpg", "image/jpeg"];

const Register = () => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const { language } = useAppContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const api = {
    signUp: apiPath.signUp,
    sendOTP: apiPath.sendOtp,
    verifyOTP: apiPath.verifyOTP,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    fc: apiPath.common.foodCategories,
    rc: apiPath.common.restaurantCategories,
  };

  const [logo, setLogo] = useState();
  const [image, setImage] = useState();
  const [document, setDocument] = useState();

  const [loading, setLoading] = useState(false);

  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [latLong, setlatLong] = useState({ lat: 30.5595, lng: 22.9375 });
  const [deliveryTypes, setDeliveryTypes] = useState([]);

  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumber2, setMobileNumber2] = useState({});

  const [registrationDetails, setRegistrationDetails] = useState();
  const [showOtpScreen, setShowOtpScreen] = useState(false);

  const { request } = useRequest();

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities(data);
        }
      },
    });
  };

  const getAreas = (id) => {
    console.log(id, "djkghjkhfgjhfjghfjghfjhgjfhgjfh");
    form.setFieldsValue({ area: undefined });
    request({
      url: `/city-area/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setarea");
        if (data) {
          setAreas(data);
        }
      },
    });
  };

  const getCategory = (country_id) => {
    request({
      url: api.rc + `?country_id=${country_id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setRestaurantCategories(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleSendOTP = async ({ mobile_number, country_code, email }) => { 
    console.log(mobile_number, country_code, email); 

    setLoading(true);
    const valid = await checkNumber({ number: country_code+ mobile_number})
    if (!valid) return setLoading(false);

    const payload = {
      mobile_number,
      country_code,
      email: email ? email : registrationDetails?.email,
      type: vendor,
      use_for: "SignUp",
    };
    request({
      url: api.sendOTP,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status == false) {
          ShowToast(data.message, Severty.ERROR);
          return;
        }
        setShowOtpScreen(true);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (err) => {
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  const handleSubmitOtp = ({ otp, mobile_number, country_code, email }) => {
    const payload = {
      mobile_number,
      country_code,
      email,
      otp: otp,
      type: vendor,
      use_for: "SignUp",
    };
    request({
      url: api.verifyOTP,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (!data?.status) {
          ShowToast(data.message, Severty.ERROR);
          return;
        } else {
          register();
          ShowToast(data.message, Severty.SUCCESS);
        }
      },
      onError: (err) => {
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  const onFinish = (values) => {
    const { location, time } = values;
    let payload = {
      ...values,
      open_time: time[0],
      close_time: time[1],
      logo: logo,
      ...mobileNumber,
      ...mobileNumber2,
      ...location,
      image: values.image[0]?.url,
      document: document,
      auto_accept_order: values?.auto_accept_order
        ? values?.auto_accept_order
        : false,
    };
    handleSendOTP({
      mobile_number: payload.mobile_number,
      country_code: payload.country_code,
      email: payload.email,
    });
    setRegistrationDetails(payload);
  };

  const register = () => {
    console.log(registrationDetails);
    request({
      url: api.signUp,
      method: "POST",
      data: registrationDetails,
      onSuccess: (data) => {
        setLoading(false);
        console.log(data, "fghdjh data");
        console.log("data.status", data.status);
        if (data.status) {
          setIsLoggedIn(true);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.data.user));
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);
          setTimeout(() => navigate("/"), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleUploadLogo = (data) => {
    setLogo(data[0]?.url);
    console.log("logo Upload");
  };

  const handleUploadDocument = (data) => {
    setDocument(data[0]?.url);
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  const handleMobileNumberChange2 = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber2({
      country_code_sec: country_code,
      mobile_number_sec: value.slice(data?.dialCode?.length),
    });
  };

  const handleUploadRestaurantImage = (data) => {
    if (data && data.length > 0) {
      const images = _.map(data, (item) => {
        return item.url;
      });
      setImage(images);
    } else setImage(null);
  };

  const handleCountryChange = (e) => {
    form.setFieldsValue({
      area: undefined,
      city_id: undefined,
      category_id: undefined,
    });
    getCities(e);
    getCategory(e);
  };

  useEffect(() => {
    getCountry();
    // getCategory();
  }, []);

  useEffect(() => {
    if (
      mobileNumber &&
      registrationDetails &&
      registrationDetails?.mobile_number &&
      registrationDetails?.country_code &&
      mobileNumber?.country_code &&
      mobileNumber?.mobile_number
    ) {
      setRegistrationDetails({
        ...registrationDetails,
        mobile_number: mobileNumber.mobile_number,
        country_code: mobileNumber.country_code,
      });
    }
  }, [mobileNumber]);

  const handleLocationChange = (value) => {
    setlatLong({
      lat: parseFloat(value.latitude),
      lng: parseFloat(value.longitude),
    });
  };

  return (
    <>
      {showOtpScreen ? (
        <Otp
          common={registrationDetails}
          verifyOTP={(data) => {
            handleSubmitOtp(data);
          }}
          loading={loading}
          handleChangeMobileNumber={handleMobileNumberChange}
          handleSendOTP={handleSendOTP}
          showCode={true}
        />
      ) : (
        <div className="sign-up-bg">
          <Layout className="signup-page">
            <div className="sign-up-header">
              <div className="signin_img">
                <img src={signinLogo} />

                <div className="country-wrap">
                  <Row className="justify-content-between mx-0">
                    <Col
                      span={24}
                      md={24}
                      lg={24}
                      xl={24}
                      className="header-control px-0"
                    >
                      <Select
                        value={language}
                        options={[
                          {
                            label: "English",
                            value: "en",
                          },
                          {
                            label: "Arabic",
                            value: "ar",
                          },
                        ]}
                        onChange={(value) => {
                          localStorage.setItem("languageSet", value);
                          window.location.reload();
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>

              <h3>
                {lang("Grow your business online with Tawasi")}
                <div style={{ fontSize: "16px", color: "#414454" }}>
                  {lang(
                    "Partner with us to reach more customers, earn more money and grow your business online - your success story begins here.",
                  )}
                </div>
              </h3>
            </div>
            <Content>
              <Form
                initialValues={{
                  have_whatsapp_sec: false,
                  have_whatsapp: false,
                  // country_id: country.country_id,
                  tax: 10,
                  commission_rate: 20,
                  online_commission_rate: 23,
                }}
                id="create"  
                form={form}
                onFinish={onFinish}
                layout="vertical"
                className="row-col"
              >
                <Row>
                  <Col md={24} lg={15} className="padding-cls">
                    <Card>
                      <Row gutter={20}>
                        <SelectInput
                          name="country_id"
                          label={lang("Country")}
                          placeholder={lang("Select country")}
                          options={countries.map((item) => ({
                            ...item,
                            name:
                              language !== "en" && language !== null
                                ? item[`${language}_name`] ?? item?.name
                                : item?.name,
                          }))}
                          handleChange={(e, key) => {
                            setCountry(e);
                            handleCountryChange(e);
                          }}
                          cover={{ md: 6 }}
                          colProps={{ sm: 24, span: 24 }}
                          showSearch
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              ?.indexOf(input.toLowerCase()) >= 0
                          }
                          rules={[
                            {
                              required: true,
                              message: lang("Missing Type Selection"),
                            },
                          ]}
                        />

                        <SelectInput
                          name="city_id"
                          label={lang("City")}
                          placeholder={lang("Select City")}
                          handleChange={getAreas}
                          options={cities.map((item) => ({
                            ...item,
                            name:
                              language !== "en" && language !== null
                                ? item[`${language}_name`] ?? item?.name
                                : item?.name,
                          }))}
                          cover={{ md: 6 }}
                          colProps={{ sm: 24, span: 24 }}
                          showSearch
                          filterOption={(input, option) => {
                            console.log(input, option, "option");
                            return (
                              option?.label
                                ?.toLowerCase()
                                ?.indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                          rules={[
                            {
                              required: true,
                              message: lang("Missing Type Selection"),
                            },
                          ]}
                        />

                        {country === Palestine.id &&
                          (console.log("areas", areas, language),
                          (
                            <SelectInput
                              name="area"
                              label={lang("Area")}
                              placeholder={lang("Enter Area")}
                              cover={{ sm: 24, md: 12 }}
                              colProps={{ sm: 24, span: 24 }}
                              showSearch
                              filterOption={(input, option) => {
                                return (
                                  option?.label
                                    ?.toLowerCase()
                                    ?.indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                              // options={areas} //TODO:  change to area
                              options={areas.map((item) => ({
                                ...item,
                                name:
                                  language !== "en" && language !== null
                                    ? item[`${language}_name`] ?? item?.name
                                    : item?.name,
                              }))}
                              rules={[
                                {
                                  required: country === Palestine.id,
                                  message: lang("Missing Type Selection"),
                                },
                              ]}
                            />
                          ))}
                      </Row>
                      <Row gutter={20}>
                        <TextInputBox
                          name="address"
                          label={lang("Address")}
                          placeholder={lang("Enter Address")}
                          cover={{ span: 24, md: 12 }}
                          colProps={{ sm: 24 }}
                          rules={[
                            {
                              max: 600,
                              message: lang(
                                "Name should contain more then 600 characters!",
                              ),
                            },
                            {
                              required: true,
                              message: lang("Please Enter Restaurant Name"),
                            },
                          ]}
                        />
                        <TextInputBox
                          name="ar_address"
                          label={lang("Address Arabic")}
                          placeholder={lang("Enter Address")}
                          rules={[
                            {
                              max: 600,
                              message: lang(
                                "Address should contain more then 600 characters!",
                              ),
                            },
                            {
                              required: true,
                              message: lang("Please Enter Restaurant Name"),
                            },
                          ]}
                        />
                      </Row>
                    </Card>

                    <Card>
                      <Row gutter={20}>
                        <SelectInput
                          className="w-100  restaurant-selected"
                          colProps={{ sm: 24, span: 24 }}
                          mode="multiple"
                          name="category_id"
                          label={lang("Restaurant Category")}
                          placeholder={lang("Select Category")}
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              ?.indexOf(input.toLowerCase()) >= 0
                          }
                          options={restaurantCategories.map((item) => ({
                            ...item,
                            name:
                              language !== "en" && language !== null
                                ? item[`${language}_name`] ?? item?.name
                                : item?.name,
                          }))}
                          rules={[
                            {
                              required: true,
                              message: lang("Missing Type Selection"),
                            },
                          ]}
                        />

                        <Col span={24} md={12}>
                          <Form.Item
                            className="mb-0"
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (
                                    value !== undefined &&
                                    value?.length > 0
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(lang("Logo is required")),
                                  );
                                },
                              },
                            ]}
                            label={lang("Upload Logo")}
                            name="logo"
                            placeholder={lang("Upload Logo")}
                          >
                            <SingleImageUpload
                              value={logo}
                              fileType={FileType}
                              imageType={"logo"}
                              isDimension={true}
                              onChange={(data) => handleUploadLogo(data)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={20}>
                        <Col span={24} md={12}>
                          <Form.Item
                            className="mb-0"
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (
                                    value !== undefined &&
                                    value?.length > 0
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(lang("Image is required")),
                                  );
                                },
                              },
                            ]}
                            label={lang("Upload Image")}
                            name="image"
                            placeholder="Upload Logo"
                          >
                            <SingleImageUpload
                              value={image}
                              fileType={FileType}
                              imageType="image"
                              onChange={(data) =>
                                handleUploadRestaurantImage(data)
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <Card>
                      <Row gutter={20}>
                        <TextInputBox
                          name="name"
                          label={lang("Restaurant’s Name")}
                          placeholder="Enter Restaurant Name"
                          rules={[
                            {
                              max: 250,
                              message: lang(
                                "Name should contain more then 250 characters!",
                              ),
                            },
                            {
                              required: true,
                              message: lang("Please Enter Restaurant Name"),
                            },
                          ]}
                        />

                        <TextInputBox
                          name="ar_name"
                          label={lang("Restaurant’s Name Arabic")}
                          placeholder={lang("Enter Restaurant Name")}
                          rules={[
                            {
                              max: 250,
                              message: lang(
                                "Name should contain more then 250 characters!",
                              ),
                            },
                            {
                              required: true,
                              message: lang("Please Enter Restaurant Name"),
                            },
                          ]}
                        />
                      </Row>
                      <Row gutter={20}>
                        <TextInputBox
                          name="description"
                          label={lang("Restaurant Description")}
                          placeholder={lang("Enter Restaurant Description")}
                          rules={[
                            {
                              max: 500,
                              message: lang(
                                "Description should contain more then 500 characters!",
                              ),
                            },
                            {
                              required: true,
                              message: lang(
                                "Please Enter Restaurant Description",
                              ),
                            },
                          ]}
                        />
                        <TextInputBox
                          name="ar_description"
                          label={lang("Restaurant Description Arabic")}
                          placeholder={lang("Enter Restaurant Description")}
                          rules={[
                            {
                              max: 500,
                              message: lang(
                                "Description should contain more then 500 characters!",
                              ),
                            },
                            {
                              required: true,
                              message: lang(
                                "Please Enter Restaurant Description",
                              ),
                            },
                          ]}
                        />
                      </Row>
                      <Row gutter={20}>
                        <Col span={24} md={12}>
                          <Form.Item
                            className="checkBox_wrap"
                            label={lang("Working Days")}
                            name="working_days"
                          >
                            <Checkbox.Group
                              onChange={(value) =>
                                console.log(value, "working")
                              }
                            >
                              {weekdays.map((item, idx) => (
                                <Checkbox value={item.name} key={idx}>
                                  {item.label}
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>

                        <Col span={24} md={12}>
                          <Form.Item
                            label={lang("Working Time")}
                            name="time"
                            rules={[
                              {
                                required: true,
                                message: lang("Please select working time"),
                              },
                            ]}
                          >
                            <TimePicker.RangePicker
                              format="h:mm a"
                              placeholder={[
                                lang("Open Time"),
                                lang("Close Time"),
                              ]}
                              use12Hours 
                              order={false}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <Row gutter={20}>
                      <Col span={24} md={12}>
                        <Card>
                          <Row>
                            <TextInputBox
                              name="contact_person_name"
                              label={lang("Contact Person Name")}
                              placeholder={lang("Enter Contact Person Name")}
                              cover={{ md: 24 }}
                              colProps={{ sm: 24, span: 24 }}
                              rules={[
                                {
                                  max: 250,
                                  message: lang(
                                    "Name should contain more then 250 characters!",
                                  ),
                                },
                                {
                                  required: true,
                                  message: lang("Please Enter Restaurant Name"),
                                },
                              ]}
                            />
                          </Row>
                          <Row>
                            <Col span={24} md={24}>
                              <Form.Item
                                label={lang("Email ID")}
                                name="email"
                                rules={[
                                  {
                                    type: "email",
                                    message: lang(
                                      "Please enter a valid email address!",
                                    ),
                                  },
                                  {
                                    max: 255,
                                    message: lang(
                                      "Email address not more then 255 characters!",
                                    ),
                                  },
                                  {
                                    required: true,
                                    message: lang("Please enter email!"),
                                  },
                                ]}
                              >
                                <Input placeholder={lang("Enter Email ID")} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <PhoneNumberField
                              label={lang("Contact Person Phone Number")}
                              name="mobile"
                              placeholder={lang("Enter Phone Number")}
                              cover={{ md: 24 }}
                              colProps={{ sm: 24, span: 24 }}
                              className=""
                              onChange={handleMobileNumberChange}
                            />
                            <Form.Item
                              name="have_whatsapp"
                              valuePropName="checked"
                            >
                              <Checkbox className="" onChange={onChange}>
                                {lang(
                                  "This number have WhatsApp to receive messages?",
                                )}
                              </Checkbox>
                            </Form.Item>
                          </Row>
                          <Row>
                            <PhoneNumberField
                              label={lang("Contact Person Phone Number")}
                              name="mobile2"
                              placeholder={lang("Enter Phone Number")}
                              cover={{ md: 24 }}
                              colProps={{ sm: 24, span: 24 }}
                              className=""
                              onChange={handleMobileNumberChange2}
                              // rules={false}
                            />
                            <Checkbox className="" onChange={onChange}>
                              {lang(
                                "This number have WhatsApp to receive messages?",
                              )}
                            </Checkbox>
                          </Row>
                        </Card>
                      </Col>
                      <Col span={24} md={12}>
                        <Card>
                          <Row>
                            <Col span={24} md={24}>
                              <Form.Item
                                label={lang("Create Password")}
                                name="password"
                                rules={[
                                  {
                                    pattern: new RegExp(
                                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                                    ),
                                    message: lang(
                                      "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                                    ),
                                  },
                                  {
                                    required: true,
                                    message: lang(
                                      "Please enter your password!",
                                    ),
                                  },
                                ]}
                              >
                                <Input.Password
                                  onCut={(e) => e.preventDefault()}
                                  onCopy={(e) => e.preventDefault()}
                                  onPaste={(e) => e.preventDefault()}
                                  autoComplete="off"
                                  placeholder={lang("Create Password")}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={24} md={24}>
                              <Form.Item
                                label={lang("Confirm Password")}
                                name="confirm_password"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: lang(
                                      "Enter the confirm password!",
                                    ),
                                  },

                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        getFieldValue("password") === value
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error(
                                          lang(
                                            "Password that you entered doesn't match!",
                                          ),
                                        ),
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <Input.Password
                                  placeholder={lang("Enter Confirm Password")}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24} lg={9}>
                    <Card>
                      <Row gutter={20}>
                        <Col md={12} sm={24} span={24}>
                          <Form.Item
                            mode="multiple"
                            name="delivery_type"
                            label={lang("Delivery Type")}
                            rules={[
                              {
                                required: true,
                                message: lang("Missing Type Selection"),
                              },
                            ]}
                          >
                            <Select
                              placeholder={lang("Select Delivery Type")}
                              className={"mb-0  restaurant-selected"}
                              onChange={(value) => {
                                setDeliveryTypes(value);
                              }}
                              autoComplete="off"
                              mode="multiple"
                            >
                              {dummyDeliveryType.map(
                                (item, index) => (
                                  console.log(
                                    "dummyDeliveryType",
                                    dummyDeliveryType,
                                  ),
                                  (
                                    <Select.Option
                                      key={item._id}
                                      value={item._id}
                                      label={item.name}
                                    >
                                      <span className="cap">
                                        {language !== ("en" || null)
                                          ? item[`${language}_name`] ??
                                            item?.name
                                          : item?.name}
                                      </span>
                                    </Select.Option>
                                  )
                                ),
                              )}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={24} md={12}>
                          <Form.Item
                            name="min_order_price"
                            label={lang("Min. Order Price")}
                            placeholder={lang("Enter Min. Order Price")}
                            cover={{ md: 10 }}
                            rules={[
                              {
                                required: true,
                                message: lang("Missing Min. Order Price"),
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder={lang("Enter Min. Order Price")}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={20}>
                        {/* {deliveryTypes.includes(dummyDeliveryType[0].name) && ( */}
                        {/* <Col span={24} md={24}>
                          <Form.Item
                            name="approx_delivery_time"
                            label="Approximate delivery time(HH:MM)"
                            rules={[
                              {
                                required: deliveryTypes.includes(
                                  dummyDeliveryType[0].name,
                                ),
                                message: "Missing Approximate delivery time",
                              },
                              {
                                validator: (_, value) => {
                                  if (
                                    /^\d{2}:\d{2}$/.test(value) ||
                                    value === ""
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Enter delivery time in HH:MM format",
                                    ),
                                  );
                                },
                              },
                            ]}
                          >
                            <Input placeholder="01:30" />
                          </Form.Item>
                        </Col> */}
                        {/* )} */}

                        <Col span={24} md={24}>
                          <Form.Item
                            name="approx_delivery_time"
                            label={lang("Approximate preparation time(HH:MM)")}
                            rules={[
                              {
                                required: true,
                                message: lang(
                                  "Missing Approximate preparation time",
                                ),
                              },
                              {
                                validator: (_, value) => {
                                  if (
                                    /^\d{2}:\d{2}$/.test(value) ||
                                    value === ""
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      lang(
                                        "Enter preparation time in HH:MM format",
                                      ),
                                    ),
                                  );
                                },
                              },
                            ]}
                          >
                            <Input placeholder="01:30" />
                          </Form.Item>
                        </Col>

                        <Col span={24} md={12}>
                          <Form.Item
                            name="tax"
                            label={lang("Store Tax %")}
                            rules={[
                              {
                                required: true,
                                message: lang(
                                  "Please Enter Store Tax Percentage",
                                ),
                              },
                            ]}
                          >
                            <InputNumber
                              formatter={(value) => `${value}%`}
                              parser={(value) => value.replace("%", "")}
                              min={0}
                              max={100}
                              placeholder={lang("Enter Tax %")}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={24} md={12}>
                          <Form.Item
                            name="commission_rate"
                            label={lang("Cash Commission Rate %")}
                            rules={[
                              {
                                required: true,
                                message: lang("Please Enter Commission Rate"),
                              },
                            ]}
                          >
                            <InputNumber
                              formatter={(value) => `${value}%`}
                              parser={(value) => value.replace("%", "")}
                              max={100}
                              placeholder="10 %"
                              min={0}
                              disabled={true}
                            />
                          </Form.Item>
                        </Col> 

                        <Col span={24} md={12}>
                          <Form.Item
                            name="online_commission_rate"
                            label={lang("Online Commission Rate %")}
                            rules={[
                              {
                                required: true,
                                message: lang("Please Enter Online Commission Rate"),
                              },
                            ]}
                          >
                            <InputNumber
                              formatter={(value) => `${value}%`}
                              parser={(value) => value.replace("%", "")}
                              max={100}
                              placeholder="10 %"
                              min={0}
                              disabled={true}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            label={lang("Auto Accept Orders")}
                            name="auto_accept_order"
                            className="auto_accept_order oreder-bar-check"
                          >
                            <Switch defaultChecked={false} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Row>
                      <Col span={24} md={24}>
                        <Form.Item
                          label={lang(
                            "Location (Drag Marker for Selecting Location)",
                          )}
                          name="location"
                          rules={[
                            {
                              required: true,
                              message: lang("Please select the location!"),
                            },
                          ]}
                        >
                          <LocationMap
                            className="mt-3"
                            onChange={handleLocationChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Card>
                      <Row gutter={20}>
                        <TextInputBox
                          name="business_id"
                          label={lang("Business Identification Number")}
                          placeholder={lang(
                            "Enter Business Identification Number",
                          )}
                          cover={{ md: 24 }}
                          rules={[
                            {
                              max: 16,
                              message: lang("Please Enter 16 digit BIN!"),
                            },
                            {
                              required: true,
                              message: lang(
                                "Enter Business Identification Number",
                              ),
                            },
                          ]}
                        />

                        <Col span={24} md={24}>
                          <Form.Item
                            className="mb-0"
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (
                                    value !== undefined &&
                                    value?.length > 0
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(lang("Logo is required")),
                                  );
                                },
                              },
                            ]}
                            label={lang("Upload legal document")}
                            name="document"
                            placeholder={lang("Upload legal document")}
                          >
                            <SingleImageUpload
                              value={logo}
                              fileType={FileType}
                              imageType={"logo"}
                              onChange={(data) => handleUploadDocument(data)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <Row gutter={20}>
                      <Col md={24}>
                        <Form.Item
                          name="terms_and_conditions"
                          valuePropName="checked"
                          rules={[
                            {
                              validator: (_, value) => {
                                if (value !== undefined && value === true) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    lang(
                                      "Please accept the terms and conditions",
                                    ),
                                  ),
                                );
                              },
                            },
                          ]}
                        >
                          <Space align="baseline">
                            <Checkbox
                            //defaultChecked={true}
                            >
                              {lang("I accept the")}{" "}
                              <Link to="/terms-and-conditions" target="_blank">
                                {lang("Contract Terms and Conditions")}
                              </Link>
                            </Checkbox>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Button
                        className="primary_btn btnStyle w-100"
                        htmlType="submit"
                      >
                        {lang("SUBMIT")}
                      </Button>
                    </Row>
                    <div style={{ textAlign: "center", margin: "32px 0" }}>
                      {lang("Already Registered")}{"? "}
                      <span className="ac-create">
                        <Link to={"/login"}> {lang("Sign In")}</Link>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Content>
          </Layout>
        </div>
      )}
    </>
  );
};

export default Register;
