import React, { createContext, useState, useContext, useEffect, useRef, useMemo } from "react";

import useRequest from "../hooks/useRequest";
import { useAuthContext } from "./AuthContext";
import socket from "../socket";
import AcceptOrderModal from "../components/AcceptOrderModal";
import AudioPlayer from "../components/AudioPlayer";

export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {
  const { request } = useRequest();
  const [language, setLanguage] = useState("en");
  const [loading, setLoading] = useState(true);
  const { userProfile } = useAuthContext()

  const socketRef = useRef(null);

  const [selectedOrder, setSelectedOrder] = useState();
  const [modal, showModal] = useState(false);
  const [playAudio, setPlayAudio] = useState(false);

  const [restaurant, setRestaurant] = useState({
    restaurant: "Restaurant",
    rating: 0,
    review: 0,
  });

  const [country, setCountry] = useState({
    country_id: undefined,
    currency: undefined,
    data: undefined,
  });


  useEffect(() => {
    if (country.country_id == "646b2e0f46865f1f65565346") {
      console.log("Import Css");
      import("../assets/styles/rtl.css");
    } else {
      console.log(" remove Import Css");
    }
  }, [country.country_id]);


  useEffect(() => {
    setLoading(true);
    let lang = localStorage.getItem("languageSet");
    lang = lang ? lang : "en";

    if (lang == "ar") {
      console.log("Import Css");
      import("../assets/styles/rtl.css");
    } else {
      import("../assets/styles/main.css");
    }

    setTimeout(() => setLoading(false), 200);
    setLanguage(lang);
  }, []);

  useEffect(() => {
    if (!userProfile) return
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 150; // Set your desired maximum number of reconnection attempts
    const reconnectInterval = 1000; // Set the interval between reconnection attempts in milliseconds

    const connectSocket = () => {
      if (socketRef.current && socketRef.current.connected) {
        console.log('Socket already connected.');
        return; // Exit if socket is already connected
      }

      // socketRef.current = socket(userDataString.username ?? 'Guest'); 

      const token = localStorage.getItem('token')
      socketRef.current = socket(token);

      socketRef.current.on('connect', () => {
        console.log('Connection established');
        reconnectAttempts = 0; // Reset the reconnect attempts on successful connection
      })

      socketRef.current.on('disconnect', () => {
        console.log('Connection is lost');
        // Retry connection if not exceeding the maximum number of attempts
        if (reconnectAttempts < maxReconnectAttempts) {
          reconnectAttempts++;
          console.log(`Attempting to reconnect (attempt ${reconnectAttempts})...`);
          setTimeout(connectSocket, reconnectInterval);
        } else {
          console.log('Max reconnect attempts reached. Disconnecting socket.');
          socketRef.current.disconnect();
        }
      })

    }

    connectSocket()

    const intervalId = setInterval(() => {
      if (!socketRef.current || !socketRef.current.connected) {
        connectSocket();
      }
    }, 3000);

    return () => {
      clearInterval(intervalId);
      if (socketRef.current) {
        console.log("Disconnect called");
        socketRef.current.disconnect();
      }
    };
  }, [socketRef, userProfile])


  useEffect(() => {
    if (!socketRef.current) return

    socketRef.current.on('newOrder', (data) => {
      console.log(data, "NEW_ORDER")

      if (data.data.is_company_order) return

      setSelectedOrder(data.data)
      setPlayAudio(true)

      setTimeout(()=>setPlayAudio(false),10000)
      showModal(true) 
     
    })
  }, [socketRef, userProfile])


  return (
    <AppStateContext.Provider
      value={{
        restaurant,
        setRestaurant,
        setCountry,
        language,
        country,

      }}
    >
      {children}
      {modal && selectedOrder && (
        <AcceptOrderModal
          section={"Home"}
          show={modal}
          hide={() => {
            showModal(false);
            // setRefresh(true);
          }}
          order={selectedOrder}
          playAudio={playAudio}
          refresh={() => {
            // setRefresh(true);
          }}
        >
          <AudioPlayer />
        </AcceptOrderModal>
      )} 

      {/* <AudioPlayer  play={playAudio}/> */}
    </AppStateContext.Provider>
  );
};
export const useAppContext = () => {
  return useContext(AppStateContext);
};
