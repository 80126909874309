export const s3Config = (type) => ({
  dirName: "tawasi/" + type,
  bucketName: "tawasi-s3",
  region: "ap-southeast-2",
  accessKeyId: "AKIA4MTWKBIIMBIQLJ67",
  secretAccessKey: "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",
});

// export const s3Config = (type) => ({
//   dirName: "tawasi/" + type,
//   bucketName: "tawasi-s3",
//   region: "ap-southeast-2",
//   accessKeyId: "AKIA4MTWKBIIKL7LQV6Q",
//   secretAccessKey: "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",
// });
