import Register from "./Auth/Register";
import Discount from "./Discount/Index";
import CmsManagement from "./CmsManagement/Index";
import Order from "./Order";
import Driver from "./Driver/Index";
import Ratings from "./Ratings/Index";
import Notification from "./Notification/Index";
import History from "./History/Index";
import Report from "./Reports/Index";
import Finance from "./Finance/Index";
import Settings from "./Settings/Index";
import Menu from "./Menu/Index";
import Revenue from "./RevenueItems/Revenue"

export {
  Register,
  Discount,
  CmsManagement,
  Order,
  Driver,
  Ratings,
  Notification,
  History,
  Report,
  Revenue,
  Finance,
  Settings,
  Menu,
};
