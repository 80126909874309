import {
  DownOutlined,
  KeyOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Rate,
  Row,
  Select,
  Switch,
  Tag,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import Logo from "../../assets/images/Logo.png";
import Notify1 from "../../assets/images/face-1.jpg";
import Notify2 from "../../assets/images/face-2.jpg";
import Notify3 from "../../assets/images/face-3.jpg";

import notfound from "../../assets/images/not_found.png";
import Notification from "../../assets/images/notification.svg";
import apiPath from "../../constants/apiPath";
import { AuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import DeleteModal from "../DeleteModal";
import ViewAndEditProfile from "./ViewAndEditProfile";
import { useAppContext } from "../../context/AppContext";
import DepsiteModal from "../../pages/Settings/_DepositModal";
import { convertStringToHtml } from "../../helper/functions";
// import ViewAndEditProfile from "./ViewAndEditProfile";

const { confirm } = Modal;

const languages = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Arabic",
    value: "ar",
  },
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ onPress, setToggle }) {
  const [visible, setVisible] = useState(false);
  const { userProfile, isAdmin, logout } = useContext(AuthContext);
  const { language, setLanguage } = useAppContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [profile, setProfile] = useState({});
  const [selected, setSelected] = useState();
  const [profileVisible, setProfileVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [appSetting, setAppSetting] = useState({});
  const [appSettingSelected, setAppSettingSelected] = useState();
  const [appSettingVisible, setAppSettingVisible] = useState(false);
  const [payment, showPayment] = useState(false);
  const { request } = useRequest();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [notification, setNotification] = useState([]);
  const [wallet, setWallet] = useState();
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const notificationitems = [
    {
      label: (
        <div className="notification_top">
          <div className="notification-head">
            <h5>{lang("Notifications")}</h5>
          </div>
          <div className="notification-inner">
            {notification.map((item) => (
              <div key={item._id} className="single-notification">
                {/* <div className="notification-img">
                  <img src={Notify1} />
                </div> */}
                <div className="notification-cont">
                  {/* <p>
                  <span>Vendor Heeba Khan</span> attach a deposit receipt to the
                  App bank account of <span>AED 100.00</span>
                </p> */}

                  <p>
                    <span>
                      {(language !== "en" && language !== null)  ? <p dangerouslySetInnerHTML={{__html: convertStringToHtml(item?.[`${language}_message`] ??  item?.message)}} />  : <p dangerouslySetInnerHTML={{__html: convertStringToHtml(item?.message)}} /> }
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="viewAll_notification">
            <Button
              onClick={() => navigate("/notifications")}
              className="btnStyle btn_primary"
            >
              {lang("View All")}
            </Button>
          </div>
        </div>
      ),
    },
  ];
  

  const items = [
    {
      label: lang("editProfile"),
      key: "1",
      icon: <UserOutlined />,
      danger: true,
    },
    {
      label: lang("changePassword"),
      key: "2",
      icon: <KeyOutlined />,
      danger: true,
    },
    {
      label: lang("logout"),
      key: "3",
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  const [isRestaurantOnline, setIsRestaurantOnline] = useState();

  const handleChangeRestaurantStatus = () => {
    //TODO: add api
    request({
      url: apiPath.changeOnline,
      method: "PUT",
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setIsRestaurantOnline(data.data.is_online);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error, Severty.Error);
      },
    });
  };

  const showDeleteConfirm = (record) => {
    setIsLogoutModalVisible(true);
  };

  const handleMenuClick = (e) => {
    if (e.key == 2) {
      setVisible(true);
    }
    if (e.key == 1) {
      setProfileVisible(true);
      setRefresh((prev) => !prev);
    }
    if (e.key == 4) {
      setAppSettingVisible(true);
    }
    if (e.key == 3) {
      showDeleteConfirm();
    }
  };

  const handleCreate = (values) => {
    onCreate(values);
  };

  const onCreate = (values) => {
    const { old_password, new_password } = values;
    const payload = {};
    if (!old_password || !new_password)
      return ShowToast("Please enter password ", Severty.ERROR);
    setLoading(true);
    payload.new_password = new_password;
    payload.old_password = old_password;
    request({
      url: apiPath.changePassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setVisible(false);
          logout();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    setIsRestaurantOnline(userProfile?.is_online);
  }, [userProfile]);

  useEffect(() => {
    request({
      url: apiPath.profile,
      method: "GET",
      onSuccess: (data) => {
        setProfile(data.data);
        setSelected(data.data);
      },
    });
    request({
      url: apiPath.getAppSetting,
      method: "GET",
      onSuccess: (data) => {
        setAppSetting(data.data);
        setAppSettingSelected(data.data);
      },
    });

    // fetchData();
  }, [refresh]);

  useEffect(() => {
    if (!isOpen) return document.body.classList.remove("edit-dropdown");
    document.body.classList.add("edit-dropdown");

    return () => {
      document.body.classList.remove("edit-dropdown");
    };
  }, [isOpen]);

  useEffect(() => window.scrollTo(0, 0));

  // useEffect(() => {
  //   if (!!userProfile && userProfile.user_language) {
  //     localStorage.setItem("languageSet", userProfile.user_language);
  //   }
  // }, [userProfile]);

  const fetchData = () => {
    request({
      url: `${apiPath.notification}/top-five`,
      method: "GET",
      onSuccess: (data) => {
        setNotification(data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const GetWallet = () => {
    request({
      url: "vendor/auth/get-wallet",
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log("walletdata", data);
        if (status) {
          setWallet(data);
        }
      },
    });
  };

  useEffect(() => {
    console.log("Notification Load");
    fetchData();
  }, [pathname])


  useEffect(() => {
    if (!!userProfile) {
      GetWallet()
    }
  }, [userProfile]);

  return (
    <>
    <Row>
      <Col span={24}>
      { wallet?.balance <  0 ? <div className="">
             <div className="  online-swich-header">
              <p>{ lang("Please add funds to your wallet as your balance is currently low")}</p> 
               <Button
                  // type="link"
                className=" top-btn-header"
                  onClick={() => showPayment(true) }
                >
                 {lang("Top Up")}
                </Button>
             </div>
           </div> : null
          }
      </Col>
    </Row>
      <Row gutter={[24, 0]} className="justify-content-between mx-0">
        <Col
          span={24}
          xs={24}
          md={24}
          sm={24}
          lg={12}
          className="SectionMain px-0"
        >
          <div className="toggale-headr">
            <div className="">
              <Button
                type="link"
                className="sidebar-toggler ps-0 d-none d-lg-block"
                onClick={() => setToggle()}
              >
                {toggler}
              </Button>
            </div>
            <div className="d-none d-lg-block">
              <div>
                <div>
                  {language !== "en" && language !== null
                    ? userProfile?.[`${language}_name`] ??
                    userProfile?.name
                    : userProfile?.name}
                  {/* {userProfile?.name ? userProfile.name : "-"} */}
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <Rate
                    disabled
                    allowHalf
                    value={
                      profile?.restaurant_id?.rating
                        ? profile?.restaurant_id?.rating
                        : 0
                    }
                  />
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="rating-text-b0ox"
                  >
                    <p className="rating-text">
                      {profile?.restaurant_id?.rating
                        ? parseFloat(profile.restaurant_id.rating).toFixed(1)
                        : 0}
                    </p>
                    <p className="rating-text">
                      {" (" +
                        (profile?.restaurant_id?.review_count
                          ? profile?.restaurant_id.review_count
                          : 0) +
                        `(${lang("Review")}))`}
                    </p>
                  </div>

                  {profile?.restaurant_id?.is_featured && (
                    <div
                      className="mt-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tag color="magenta">{lang("Featured")}</Tag>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="tabLogo d-block d-lg-none ">
            <img className="w-100" src={Logo} />
          </div>
        </Col>

        <Col span={24} xs={24} sm={24} lg={12} className="header-control px-0">
          <Button
            type="link"
            className="sidebar-toggler ps-0"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
 
         
          
          <div className="notificationDropdownMain">
            <div className="  online-swich">
              {isRestaurantOnline ? lang("Available") : lang("Offline")}
              <Switch
                style={{ marginLeft: 10 }}
                onChange={handleChangeRestaurantStatus}
                checked={isRestaurantOnline}
              />
            </div>
          </div> 


          <div className="country-wrap">
            <Select
              value={language}
              options={languages}
              onChange={(value) => {
                localStorage.setItem("languageSet", value);
                window.location.reload();
              }}
            />
          </div>
          <div className="notificationDropdownMain">
            <div className="notification-header d-lg-block">
              <Dropdown
                menu={{
                  items: notificationitems,
                  className: "top-header-notify",
                }}
                trigger={["click"]}
                className="notification-box"
              >
                <Button>
                  <img src={Notification} />
                  <span className="active_notification"></span>
                </Button>
              </Dropdown>
            </div>
          </div>

          <div className="profileDropdownMain">
            <Dropdown
              open={isOpen}
              onOpenChange={(open) => setIsOpen(open)}
              className="edit-box"
              menu={menuProps}
              trigger={["click"]}
            >
              <Button className="ant-btn ant-btn-default ant-dropdown-trigger ant-dropdown-open">
                <div className=" d-flex align-items-center gap-2">
                  <div className="userImg">
                    <Image
                      src={profile ? profile?.restaurant_id?.logo : notfound}
                      preview={false}
                    />
                  </div>
                  <div className="d-none d-xl-block">
                    <div className="userName">
                      {profile ?
                        language !== "en" && language !== null
                          ? profile?.[`${language}_name`] ??
                          profile.name
                          : profile.name
                        :
                        "Administrator"}
                      <DownOutlined />
                    </div>
                  </div>
                </div>
              </Button>
            </Dropdown>
          </div>
        </Col>
      </Row>

      {isLogoutModalVisible && (
        <DeleteModal
          title={lang("Logout")}
          subtitle={lang(`Are you sure you want to Logout the Application?`)}
          show={isLogoutModalVisible}
          hide={() => {
            setIsLogoutModalVisible(false);
          }}
          onOk={() => logout()}
        />
      )} 

      {
       payment && <DepsiteModal show={payment} data={wallet} hide={()=>showPayment(false)}/>
      }

      {profileVisible && (
        <ViewAndEditProfile
          show={profileVisible}
          hide={() => {
            setProfileVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {appSettingVisible && (
        <AppSetting
          show={appSettingVisible}
          hide={() => {
            setAppSettingVisible(false);
          }}
          data={appSettingSelected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {visible && (
        <ChangePassword
          show={visible}
          hide={() => setVisible(false)}
          handleCreate={handleCreate}
        />
      )}
    </>
  );
}

const ChangePassword = ({ handleCreate, hide, show }) => {
  const [formChange] = Form.useForm();

  return (
    <Modal
      open={show}
      // title="Change password"
      okText="Ok"
      onCancel={hide}
      //onOk={handleCreate}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        //loading: loading,
      }}
    >
      <h4 className="modal_title_cls">{lang("Change Password")}</h4>
      <Form
        id="create"
        form={formChange}
        onFinish={handleCreate}
        layout="vertical"
      >
        <Form.Item
          label={lang("Old Password")}
          name="old_password"
          hasFeedback
          rules={[
            { required: true, message: lang("Please enter the old password!") },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={lang("New Password")}
          name="new_password"
          dependencies={["old_password"]}
          hasFeedback
          rules={[
            { required: true, message: lang("Please enter the new password!") },
            {
              pattern: new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
              ),
              message:
                "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("old_password") === value) {
                  return Promise.reject(
                    new Error(lang("Old password & new password must be different")),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={lang("Confirm New Password")}
          name="confirm_new_password"
          dependencies={["new_password"]}
          hasFeedback
          rules={[
            { required: true, message: lang("Please enter the confirm password!") },
            {
              pattern: new RegExp(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
              ),
              message:
                "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Confirm password & password does not match!"),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const AppSetting = ({ show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ ...data });
  }, [data]);

  const onAppSetting = (values) => {
    setLoading(true);
    request({
      url: apiPath.updateAppSetting,
      method: "POST",
      data: values,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      width={1200}
      visible={show}
      title={`${data ? "Update App Setting" : ""}`}
      okText="Ok"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
    >
      <Form id="create" form={form} onFinish={onAppSetting} layout="vertical">
        <Row gutter={{ xs: [0, 16], md: [16, 0] }}>
          <Col span={24} md={12}>
            <Card title="Android Details">
              <Col span={24}>
                <Form.Item
                  label="App Store URL"
                  name="app_store_url"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the app store URL!",
                    },
                  ]}
                >
                  <Input placeholder="Enter App Store URL" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Version"
                  name="android_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input placeholder="Enter Android Version" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Share Content"
                  name="android_share_content"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the share content!",
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share Android Content"
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>

          <Col span={24} md={12}>
            <Card title="IOS Details">
              <Col span={24} className="">
                <Form.Item
                  label="Play Store URL"
                  name="play_store_url"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the play store URL!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Play Store URL" />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item
                  label="Version"
                  name="ios_version"
                  rules={[
                    { required: true, message: "Please enter the version!" },
                  ]}
                >
                  <Input placeholder="Enter IOS Version" />
                </Form.Item>
              </Col>

              <Col span={24} className="">
                <Form.Item
                  label="Share Content"
                  name="ios_share_content"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the share content!",
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    style={{ height: 120, marginBottom: 15 }}
                    placeholder="Share IOS Content"
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Header;
