import { Card, Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";

import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import useRequest from "../../hooks/useRequest";
import OrderHistory from "./_OrderHistory";
import CompanyOrderHistory from "./_CompanyOrderHistory";

const { TabPane } = Tabs;

export const MenuTabs = {
    ORDER: "Order History",
    ScheduleOrder: "Company Order History",

};

function Index() {
    const sectionName = "Menu";
    const routeName = "menu";

    const api = {
        import: apiPath.uploadMenu,
    };

    const { request } = useRequest();

    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isUploadVisible, setIsUploadVisible] = useState(false);

    const [selectedTab, setSelectedTab] = useState(lang(MenuTabs.UPLOAD));

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    useEffect(() => {
        setLoading(true);
    }, [refresh, selectedTab]);

    return (
        <>
            <div className="tabled quoteManagement">
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card bordered={false} className="criclebox tablespace mb-24">
                        {/* <Order section={sectionName} /> */}
                            <Tabs
                                className="main_tabs"
                                onTabClick={handleTabChange}
                                activeKey={selectedTab}
                                tabBarStyle={{ color: "green" }}
                            >
                                <TabPane
                                    tab={lang(MenuTabs.ORDER)}
                                    key={MenuTabs.ORDER}
                                >
                                    <OrderHistory section={sectionName} />
                                </TabPane>

                                <TabPane
                                    tab={lang(MenuTabs.ScheduleOrder)}
                                    key={MenuTabs.ScheduleOrder}
                                >
                                    <CompanyOrderHistory section={sectionName} />
                                </TabPane>

                            </Tabs>
                        </Card>
                    </Col>
                </Row>

            </div>
        </>
    );
}

export default Index;
