import { Badge, Card, Col, Row, Table, Tabs, Tag } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import AcceptOrderModal from "../../components/AcceptOrderModal";
import Currency from "../../components/Currency";
import SectionWrapper from "../../components/SectionWrapper";
import ViewOrderModal from "../../components/ViewOrderModal";
import apiPath from "../../constants/apiPath";
import {
  DriverOrderStatus,
  OrderStatus,
} from "../../constants/order-status.constants";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import { lang } from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";

const { TabPane } = Tabs;

function Home() {
  const { userProfile } = useContext(AuthContext);
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const api = {
    list: apiPath.dashboard,
  };

  const [list, setList] = useState();
  const [companyOrder, setCompanyOrder] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [years, setYears] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("all");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
    total: 0,
  });

  const { language } = useAppContext();
  const [selectedOrder, setSelectedOrder] = useState();
  const [isOrderAcceptModalVisible, setIsOrderAcceptModalVisible] =
    useState(false);
  const [isOrderViewModalVisible, setIsOrderViewModalVisible] = useState(false);
  const [playAudio, setPlayAudio] = useState(false);

  const pendingOrderColumns = [
    {
      title: lang("Order ID"),
      dataIndex: "index",
      key: "index",
      render: (_, { uid, created_at, status }) => {
        let statusColor = "";
        let text = "";

        const currentTime = new Date();
        const orderTime = new Date(created_at);
        const minutesElapsed = (currentTime - orderTime) / 1000 / 60;

        if (status === OrderStatus.PROCESSING) {
          statusColor = "cyan";
          if (minutesElapsed > 3) {
            statusColor = "red";
            text = lang("Delayed");
          }
        }

        return (
          <span className="cap" style={{ color: statusColor }}>
            {uid ? `#${uid}` : "-"} - {text}
          </span>
        );
      },
    },

    {
      title: lang("Status"),
      key: "action",
      render: (_, record) => {
        return (
          <Tag
            color="warning"
            onClick={() => {
              setSelectedOrder(record);
              setIsOrderAcceptModalVisible(true);
            }}
            style={{ cursor: "pointer" }}
          >
            {record?.restaurant_status === "pending"
              ? lang("New Order")
              : lang(record?.restaurant_status)}
          </Tag>
        );
      },
    },
    // {
    //   title: lang("View"),
    //   key: "info",
    //   render: (_, record) => {
    //     return (
    //       <Tag
    //         title={lang("View")}
    //         // className="btnStyle btnOutlineDelete"
    //         onClick={() => {
    //           setSelectedOrder(record);
    //           setIsOrderViewModalVisible(true);
    //         }}
    //         style={{ cursor: "pointer" }}
    //       >
    //         {lang("View")}
    //       </Tag>
    //     );
    //   },
    // },

    {
      title: lang("items"),
      key: "items",
      render: (_, { items }) =>
        items ? (
          <>
            {items.map((item, index) => {
              return (
                <span className="cap" key={index}>
                  {language !== "en" && language !== null
                    ? item?.food_id?.[`${language}_name`] ?? item?.food_id?.name
                    : item?.food_id?.name}
                  {" X " + item?.qty}
                </span>
              );
            })}
          </>
        ) : (
          "-"
        ),
    },
    {
      title: lang("date"),
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? (
          <span className="cap">{dateString(created_at, "lll")}</span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Payment Mod"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { payment_mod }) =>
        payment_mod ? <span className="cap">
          {payment_mod == "cod" ? lang(payment_mod) : lang('Online Payment')}
        </span> : "-",
    },
    {
      title: lang("Price"),
      key: "total_payable",
      render: (_, { total_payable, total_amount }) =>
        total_payable ? (
          <span className="cap">
            {" "}
            <Currency price={total_amount} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Platform Commission"), //TODO: pending
      key: "admin_commission",
      render: (_, { platform_commission }) =>
        platform_commission ? (
          <span className="cap">
            <Currency price={platform_commission?.restaurant ?? 0} />
          </span>
        ) : (
          "-"
        ),
    },
  ];

  const inProgressColumns = [
    {
      title: lang("Order ID"),
      dataIndex: "index",
      key: "index",
      render: (_, { uid, created_at, status }) => {
        let statusColor = "";
        let text = "";

        const currentTime = new Date();
        const orderTime = new Date(created_at);
        const minutesElapsed = (currentTime - orderTime) / 1000 / 60;

        if (status === OrderStatus.PROCESSING) {
          if (minutesElapsed > 3) {
            statusColor = "red";
            text = lang("Delayed");
          }
        }

        return (
          <span className="cap" style={{ color: statusColor }}>
            {uid ? `#${uid}` : "-"} {text}
          </span>
        );
      },
    },

    {
      title: lang("Order status"),
      key: "action",
      render: (_, { driver_status, status, restaurant_status }) => {
        let statusColor = "yellow";
        let text = "Pending";
        if (status === OrderStatus.PROCESSING) {
          statusColor = "cyan";
          text = "Preparing";
        } else if (status === OrderStatus.READY) {
          statusColor = "cyan";
          text = "Ready to pickup";
        } else if (driver_status === DriverOrderStatus.DROP) {
          statusColor = "lime";
          text = "Driver arrived at customer";
        } else if (driver_status === DriverOrderStatus.OUT) {
          statusColor = "lime";
          text = "Out for delivery";
        } else if (driver_status === DriverOrderStatus.PICKUP) {
          statusColor = "lime";
          text = "Picked up by driver";
        }
        return (
          <Badge
            className="cap"
            status={statusColor ? statusColor : "default"}
            text={lang(text)}
          />
        );
      },
    },
    {
      title: lang("View"),
      key: "info",
      render: (_, record) => {
        return (
          <Tag
            title="View"
            // className="btnStyle btnOutlineDelete"
            onClick={() => {
              setSelectedOrder(record);
              setIsOrderViewModalVisible(true);
            }}
            style={{ cursor: "pointer" }}
          >
            {lang("View")}
          </Tag>
        );
      },
    },

    {
      title: lang("items"),
      key: "items",
      render: (_, { items }) =>
        items ? (
          <>
            {items.map((item, index) => {
              return (
                <span className="cap" key={index}>
                  {language !== "en" && language !== null
                    ? item?.food_id?.[`${language}_name`] ?? item?.food_id?.name
                    : item?.food_id?.name}
                  {" X " + item?.qty}
                </span>
              );
            })}
          </>
        ) : (
          "-"
        ),
    },
    {
      title: lang("date"),
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? (
          <span className="cap">{dateString(created_at, "lll")}</span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Payment Mod"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { payment_mod }) =>
        payment_mod ? <span className="cap">
          {payment_mod == "cod" ? lang(payment_mod) : lang('Online Payment')}
        </span> : "-",
    },
    {
      title: lang("Price"),
      key: "total_payable",
      render: (_, { total_payable, total_amount }) =>
        total_payable ? (
          <span className="cap">
            {" "}
            <Currency price={total_amount} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Platform Commission"), //TODO: pending
      key: "admin_commission",
      render: (_, { platform_commission }) =>
        platform_commission ? (
          <span className="cap">
            <Currency price={platform_commission?.restaurant ?? 0} />
          </span>
        ) : (
          "-"
        ),
    },
  ];

  const delivery = [
    {
      title: lang("Driver Profile"),
      dataIndex: "name",
      key: "name",
      render: (_, { driver_id }) =>
        driver_id ? <span className="cap">{driver_id.name}</span> : "-",
    },
    {
      title: lang("Order ID"),
      key: "items",
      render: (_, { uid }) => (uid ? <span className="cap">#{uid}</span> : "-"),
    },
    {
      title: lang("View"),
      key: "info",
      render: (_, record) => {
        return (
          <Tag
            title={lang("View")}
            // className="btnStyle btnOutlineDelete"
            onClick={() => {
              setSelectedOrder(record);
              setIsOrderViewModalVisible(true);
            }}
            style={{ cursor: "pointer" }}
          >
            {lang("View")}
          </Tag>
        );
      },
    },
    {
      title: lang("Action"),
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? (
          <span className="cap">
            {moment(created_at).format("DD-MMM-YYYY")}
          </span>
        ) : (
          "-"
        ),
    },

    {
      title: lang("Status"),
      key: "action",
      render: (_, { status }) => {
        let statusColor = status === OrderStatus.CANCEL ? "red" : "yellow";
        return (
          <Badge
            className="cap"
            status={statusColor ? statusColor : "default"}
            text={lang(status)}
          />
        );
      },
    },
    {
      title: "",
      fixed: "info",
      key: "info",
      render: (_, { customer_id }) => {
        return customer_id?.name;
      },
    },
  ];

  const pickorders = [
    {
      title: lang("Order ID"),
      key: "items",
      render: (_, { uid }) => (uid ? <span className="cap">#{uid}</span> : "-"),
    },
    {
      title: lang("customer"),
      dataIndex: "name",
      key: "name",
      render: (_, { customer_id }) =>
        customer_id ? <span className="cap">{customer_id.name}</span> : "-",
    },
    {
      title: lang("Status"),
      key: "action",
      render: (_, record) => {
        let statusColor = "yellow";
        if (record.status === OrderStatus.DELIVER) {
          statusColor = "green";
        } else if (record.status === OrderStatus.PROCESSING) {
          statusColor = "cyan";
        }
        return (
          <Tag
            color="warning"
            onClick={() => {
              setSelectedOrder(record);
              setIsOrderAcceptModalVisible(true);
            }}
            style={{ cursor: "pointer" }}
          >
            {record?.restaurant_status == "pending"
              ? lang("New Order")
              : lang(record?.restaurant_status)}
          </Tag>
        );
      },
    },
    {
      title: lang("items"),
      key: "items",
      render: (_, { items }) =>
        items ? (
          <>
            {items.map((item, index) => {
              return (
                <span className="cap" key={index}>
                  {/* {item?.food_id?.name + " X " + item?.qty}  */}

                  {language !== "en" && language !== null
                    ? item?.food_id?.[`${language}_name`] ?? item?.food_id?.name
                    : item?.food_id?.name}
                  {" X " + item?.qty}
                </span>
              );
            })}
          </>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Order Time"),
      key: "created_at",
      render: (_, { created_at }) =>
        created_at ? (
          <span className="cap">{dateString(created_at, "lll")}</span>
        ) : (
          "-"
        ),
    },

    {
      title: lang("Price"),
      key: "total_payable",
      render: (_, { total_payable, total_amount }) =>
        total_payable ? (
          <span className="cap">
            <Currency price={total_amount} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Platform Commission"), //TODO: pending
      key: "admin_commission",
      render: (_, { platform_commission }) =>
        platform_commission ? (
          <span className="cap">
            <Currency price={platform_commission?.restaurant ?? 0} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Payment Mod"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { payment_mod }) =>
        payment_mod ? <span className="cap">
          {payment_mod == "cod" ? lang(payment_mod) : lang('Online Payment')}
        </span> : "-",
    },

    {
      title: "",
      fixed: "info",
      key: "info",
      render: (_, { driver_id }) => {
        return driver_id?.name;
      },
    },
  ];

  const scheduleOrder = [
    {
      title: lang("Order ID"),
      dataIndex: "index",
      key: "index",
      render: (_, { uid }) => (uid ? <span className="cap">#{uid}</span> : "-"),
    },
    {
      title: lang("customer"),
      dataIndex: "name",
      key: "name",
      render: (_, { customer_id }) =>
        customer_id ? (
          <div style={{ display: "flex", gap: 2, flexDirection: "column" }}>
            {customer_id?.name && (
              <span className="cap">{customer_id.name}</span>
            )}
            {customer_id?.country_code && customer_id?.mobile_number && (
              <span className="cap" style={{ fontSize: "14px", color: "gray" }}>
                ({customer_id.country_code}) {customer_id.mobile_number}
              </span>
            )}
            {customer_id?.email && (
              <span style={{ fontSize: "14px", color: "gray" }}>
                {customer_id.email}
              </span>
            )}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: lang("items"),
      key: "items",
      render: (_, { items }) =>
        items ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {items.map((item, index) => {
              return (
                <span
                  className="cap"
                  key={index}
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  {item?.food_id?.name + " X " + item?.qty}
                </span>
              );
            })}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Payment Method"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { payment_mod }) =>
        payment_mod ? (
          <span style={{ textTransform: "uppercase" }}>{payment_mod}</span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Schedule time"),
      key: "created_at",
      render: (_, { schedule_time }) => {
        return schedule_time ? (
          <span className="cap">
            {/* {convertTime(created_at)} */}
            {dateString(schedule_time, "lll")}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Price"),
      key: "total_payable",
      render: (_, { total_payable, total_amount }) =>
        total_payable ? (
          <span className="cap">
            <Currency price={total_amount} />
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: lang("Platform Commission"), //TODO: pending
      key: "admin_commission",
      render: (_, { platform_commission }) =>
        platform_commission ? (
          <span className="cap">
            <Currency price={platform_commission.restaurant} />
          </span>
        ) : (
          "-"
        ),
    },
  ];

  const comapnyOrderColumns = [
    {
      title: lang("Company code"),
      dataIndex: "index",
      key: "index",
      render: (_, { uid, _id, company }) => {
        let statusColor = "";
        let text = "";
        return (
          <span className="cap" style={{ color: statusColor }}>
            {company ? `${company.code}` : "-"}
          </span>
        );
      },
    },
    {
      title: lang("Break Slot"),
      key: "action",
      render: (_, { _id }) => {
        return (
          <span className="cap" >
            {_id?.break_slot_time ? `${dateString(_id.break_slot_time)}` : "-"}
          </span>
        );
      },
    },
    {
      title: lang("Number of Orders"),
      dataIndex: "payment_mod",
      key: "payment_mod",
      render: (_, { orders }) =>
        orders ? <span className="cap">{orders.length}</span> : "-",
    },
    {
      title: lang("View"),
      key: "info",
      render: (_, { _id }) => {
        return (
          <Tag
            title={lang("View")}
            // className="btnStyle btnOutlineDelete"
            onClick={() => {
              navigate(`/order/${_id?.company_id}/company/${_id?.break_slot_time}`)
            }}
            style={{ cursor: "pointer" }}
          >
            {lang("View")}
          </Tag>
        );
      },
    },
  ];

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url: api.list,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(data);
          if (data?.pendingOrders.length > 0) {
            // setSelectedOrder(data?.pendingOrders?.[0]);
            // setIsOrderAcceptModalVisible(true);
            // setPlayAudio(true);
          }
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchCompany = () => {
    request({
      url: api.list + "/company",
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          console.log(data?.orders, "data?.orders");
          if (data?.orders.length > 0) {
            setCompanyOrder(data?.orders)
          }
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    getFilters();
    setLoading(true);
    fetchData({ current: 1 }, selectedTab);
    fetchCompany()
  }, [refresh, debouncedSearchText, selectedTab]);

  const getFilters = () => {
    request({
      url: `${apiPath.dashboard}/sales/filters`,
      method: "GET",
      onSuccess: (res) => {
        if (res.status) {
          const { data, months, years } = res;
          setYears(years);
        }
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <>
      <div className="dash-layout-content">
        {userProfile && userProfile.approveStatus === "pending" && !list && (
          <Row>
            <Col xs={24} sm={24} md={20}>
              <div className="dash-layout-box">
                <div className="layout-icon-box">
                  <span>
                    <i class="far fa-clock"></i>
                  </span>
                </div>
                <div className="layout-text-box">
                  <h4>{lang("restaurantWaitingMessage")}</h4>
                  <p>{lang("restaurantWaitingDescription")}</p>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>

      <div className="dash-layout-content">
        {userProfile &&
          userProfile.approveStatus === "approved" &&
          (!list ||
            (!list?.pendingOrders.length &&
              !list?.todaysOrders.length &&
              !list?.sales.length &&
              !list?.outForDeliveryOrders.length)) ? (
          <Row className="mt-3">
            <Col xs={24} sm={24} md={20}>
              <div className="dash-layout-box">
                <div className="layout-icon-box">
                  <span>
                    <i class="far fa-thumbs-up"></i>
                  </span>
                </div>
                <div className="layout-text-box">
                  <h4>{lang("restaurantApprovalMessage")}</h4>
                  <p></p>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <div style={{ height: "100%" }}>
            <div className="dash-layout-main mb-3" style={{ height: "100%" }}>
              <Row gutter={[24, 0]}>
                <Col xs={24} xl={6} sm={12} className="mb-2">
                  <Card>
                    <div className="dash-box-top">
                      <h4>{list?.progressOrder ? list?.progressOrder : 0}</h4>
                      <p>{lang("Orders In Progress")}</p>
                      <div className="topbar-icons">
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} xl={6} sm={12} className="mb-2">
                  <Card>
                    <div className="dash-box-top">
                      <h4>
                        {list?.outForDeliveryOrders
                          ? list?.outForDeliveryOrders.length
                          : 0}
                      </h4>
                      <p>{lang("Orders Out For Delivery")}</p>
                      <div className="topbar-icons">
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} xl={6} sm={12} className="mb-2">
                  <Card>
                    <div className="dash-box-top">
                      <h4>
                        {list?.todaySale ? (
                          <Currency
                            price={
                              list?.todaySale.cod_amount +
                              list?.todaySale?.online_amount
                            }
                          />
                        ) : (
                          0
                        )}
                      </h4>
                      <p>{lang("Today's Sales")}</p>
                      <div className="topbar-icons">
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} xl={6} sm={12} className="mb-2">
                  <Card>
                    <div className="dash-box-top">
                      <h4>{list?.todaysTotal ? list?.todaysTotal : 0}</h4>
                      <p>{lang("Today's Orders")}</p>
                      <div className="topbar-icons">
                        <i class="far fa-user"></i>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
            <Row gutter={[24, 0]}>
              <Col
                xs={24}
                xl={14}
                className="sm-padding-0 "
                style={{ height: "100%" }}
              >
                <SectionWrapper
                  cardHeading={lang("NEW ORDERS")}
                  extra={
                    <>
                      <div className="w-100 text-head_right_cont">
                        <div className="pageHeadingSearch desh-input-search">
                          {/* <Input.Search
                            className="searchInput"
                            placeholder={lang("search")}
                            onChange={onSearch}
                            allowClear
                          /> */}
                          {/* <Link className="button-print-now">
                            <i class="fas fa-print"></i>
                          </Link> */}
                        </div>
                      </div>
                    </>
                  }
                >
                  <div
                    className="table-responsive customPagination withOutSearilNo"
                    style={{ height: "100%" }}
                  >
                    <Table
                      loading={loading}
                      columns={pendingOrderColumns}
                      dataSource={list?.pendingOrders}
                      // pagination={{
                      //   // defaultPageSize: 10,
                      //   // responsive: true,
                      //   // total: pagination.total,
                      //   ...pagination,
                      //   showSizeChanger: true,
                      //   pageSizeOptions: ["10", "20", "30", "50"],
                      // }}
                      // pagination={true}
                      // onChange={handleChange}
                      pagination={{
                        defaultPageSize: 5,
                        responsive: true,
                        showSizeChanger: true,
                        pageSizeOptions: ["3", "5", "10", "20"],
                      }}
                      className="ant-border-space"
                    />
                  </div>
                </SectionWrapper>
              </Col>
              <Col
                xs={24}
                xl={10}
                className="sm-padding-0 "
                style={{ height: "100%" }}
              >
                <SectionWrapper
                  cardHeading={lang("PROGRESSING ORDERS")}
                  extra={
                    <>
                      <div className="w-100 text-head_right_cont">
                        <div className="pageHeadingSearch desh-input-search">
                          {/* <Input.Search
                            className="searchInput"
                            placeholder={lang("search")}
                            onChange={onSearch}
                            allowClear
                          /> */}
                          {/* <Link className="button-print-now">
                            <i class="fas fa-print"></i>
                          </Link> */}
                        </div>
                      </div>
                    </>
                  }
                >
                  <div
                    className="table-responsive customPagination withOutSearilNo"
                    style={{ height: "100%" }}
                  >
                    <Table
                      loading={loading}
                      columns={inProgressColumns}
                      dataSource={list?.inProgress ?? []}
                      pagination={{
                        defaultPageSize: 5,
                        responsive: true,
                        showSizeChanger: true,
                        pageSizeOptions: ["3", "5", "10", "20"],
                      }}
                      // pagination={true}
                      // onChange={handleChange}
                      className="ant-border-space"
                    />
                  </div>
                </SectionWrapper>
              </Col>
              <Col xs={24} xl={14} className="sm-padding-0 ">
                <SectionWrapper
                  cardHeading={lang("Today's Pickup Orders")}
                  extra={
                    <>
                      <div className="w-100 text-head_right_cont">
                        <div className="pageHeadingSearch desh-input-search">
                          {/* <Input.Search
                            className="searchInput"
                            placeholder={lang("search")}
                            onChange={onSearch}
                            allowClear
                          /> */}
                          {/* <Link className="button-print-now">
                            <i class="fas fa-print"></i>
                          </Link> */}
                        </div>
                      </div>
                    </>
                  }
                >
                  <div className="table-responsive customPagination withOutSearilNo">
                    <Table
                      loading={loading}
                      columns={pickorders}
                      dataSource={list?.todaysOrders}
                      // pagination={{
                      //   // defaultPageSize: 5,
                      //   // responsive: true,
                      //   // total: pagination.total,
                      //   ...pagination,
                      //   showSizeChanger: true,
                      //   pageSizeOptions: ["10", "20", "30", "50"],
                      // }}
                      // onChange={handleChange}
                      pagination={{
                        defaultPageSize: 5,
                        responsive: true,
                        showSizeChanger: true,
                        pageSizeOptions: ["3", "5", "10", "20"],
                      }}
                      className="ant-border-space"
                    />
                  </div>
                </SectionWrapper>
              </Col>


              {/* <Col xs={24} xl={12} className="sm-padding-0 ">
                <SectionWrapper
                  cardHeading={lang("Schedule Order List")}
                  extra={
                    <>
                      <div className="w-100 text-head_right_cont">
                        <div className="pageHeadingSearch desh-input-search">
                         
                          <Link className="button-print-now">
                            <i class="fas fa-print"></i>
                          </Link>
                        </div>
                      </div>
                    </>
                  }
                >
                  <div className="table-responsive customPagination withOutSearilNo">
                    <Table
                      loading={loading}
                      columns={scheduleOrder}
                      dataSource={list?.ScheduleOrder}
                      pagination={{
                        defaultPageSize: 10,
                        responsive: true,
                        total: pagination.total,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                      }}
                      onChange={handleChange}
                      className="ant-border-space"
                    />
                  </div>
                </SectionWrapper>
              </Col> */}
              {/* <Col xs={24} xl={10} className="sm-padding-0 ">
                <SectionWrapper
                  cardHeading={lang("Sales")}
                  extra={
                    <>
                      <div className="w-100 text-head_right_cont">
                        <div className="role-wrap">
                          <Select
                            width="110px"
                            placeholder={lang("year")}
                            showSearch
                            value={filter.year}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            options={years.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            onChange={(value) => onChange("year", value)}
                          />
                        </div>
                      </div>
                    </>
                  }
                >
                  <Tabs className="main_tabs">
                    <TabPane tab={lang("monthly")} key="Monthly">
                      <div style={{ padding: "25px" }}>
                        <MonthlyChart year={filter.year} />
                      </div>
                    </TabPane>
                    <TabPane tab={lang("weekly")} key="Weekly">
                      <div style={{ padding: "25px" }}>
                        <WeeklyChart year={filter.year} />
                      </div>
                    </TabPane>
                    <TabPane tab={lang("quarterly")} key="Quarterly">
                      <div style={{ padding: "25px" }}>
                        <QuarterlyChart year={filter.year} />
                      </div>
                    </TabPane>
                  </Tabs>
                </SectionWrapper>
              </Col> */}

              <Col xs={24} xl={10} className="sm-padding-0 ">
                <SectionWrapper
                  cardHeading={lang("Out For Delivery")}
                  extra={
                    <>
                      <div className="w-100 text-head_right_cont">
                        <div className="pageHeadingSearch desh-input-search">
                          {/* <Input.Search
                            className="searchInput"
                            placeholder={lang("search")}
                            onChange={onSearch}
                            allowClear
                          /> */}
                          {/* <Link className="button-print-now">
                            <i class="fas fa-print"></i>
                          </Link> */}
                        </div>
                      </div>
                    </>
                  }
                >
                  <div className="table-responsive customPagination withOutSearilNo">
                    <Table
                      loading={loading}
                      columns={delivery}
                      dataSource={list?.outForDeliveryOrders}
                      // pagination={{
                      //   ...pagination,
                      //   showSizeChanger: true,
                      //   pageSizeOptions: ["10", "20", "30", "50"],
                      // }}
                      // onChange={handleChange}
                      pagination={{
                        defaultPageSize: 5,
                        responsive: true,
                        showSizeChanger: true,
                        pageSizeOptions: ["3", "5", "10", "20"],
                      }}
                      className="ant-border-space"
                    />
                  </div>
                </SectionWrapper>
              </Col>


              <Col xs={24} xl={14} className="sm-padding-0 ">
                <SectionWrapper
                  cardHeading={lang("Today's Company Orders")}
                  extra={
                    <>
                      <div className="w-100 text-head_right_cont">
                        <div className="pageHeadingSearch desh-input-search">
                          {/* <Link className="button-print-now">
                            <i class="fas fa-print"></i>
                          </Link> */}
                        </div>
                      </div>
                    </>
                  }
                >
                  <div className="table-responsive customPagination withOutSearilNo">
                    <Table
                      loading={loading}
                      columns={comapnyOrderColumns}
                      dataSource={companyOrder}
                      pagination={{
                        defaultPageSize: 5,
                        responsive: true,
                        showSizeChanger: true,
                        pageSizeOptions: ["3", "5", "10", "20"],
                      }}
                      className="ant-border-space"
                    />
                  </div>
                </SectionWrapper>
              </Col>
            </Row>
          </div>
        )}

        {isOrderAcceptModalVisible && selectedOrder && (
          <AcceptOrderModal
            section={"Home"}
            show={isOrderAcceptModalVisible}
            hide={() => {
              setIsOrderAcceptModalVisible(false);
              // setRefresh(true);
            }}
            order={selectedOrder}
            playAudio={playAudio}
            refresh={() => {
              setRefresh(prev => !prev);
            }}
          />
        )}

        {isOrderViewModalVisible && selectedOrder && (
          <ViewOrderModal
            show={isOrderViewModalVisible}
            hide={() => {
              setIsOrderViewModalVisible(false);
              setRefresh(true);
            }}
            data={selectedOrder}
          />
        )}
      </div>
    </>
  );
}

export default Home;
