import { Button, message, Upload, Image } from "antd";
import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { uploadFile, deleteFile } from "react-s3";
import { s3Config } from "../config/s3Config";
import { ShowToast, Severty } from "../helper/toast";
import { getFileExtension } from "../helper/functions";

import lang from "../helper/langHelper";
import { uploadToS3 } from "../helper/s3";

const SingleImageUpload = ({
  fileType,
  value,
  imageType,
  btnName,
  onChange,
  size = 1,
  isDimension = false,
  ...props
}) => {
  const [file, setFile] = useState([]);

  const handleImgChange = async (event) => {

    const { file } = event;

    setFile([file]);

    const extension = getFileExtension(file.name);

    const name = `TAWASI_${new Date().getTime()}.${extension}`;

    const newFile = new File([file], name, { type: file.type });
    //   await uploadFileAws({file:newFile,type:imageType})

    uploadToS3({ file: newFile, type: imageType })
      .then((data) => {
        console.log("File Upload", newFile, data);
        const fileData = {
          uid: file.uid,
          name: name,
          status: "done",
          url: data.location,
          thumbUrl: data.location,
        };
        setFile([fileData]);
        if (onChange) {
          onChange([fileData]);
        }
      })
      .catch((err) => console.error(err));
    /*  uploadFile(newFile, s3Config(imageType))
         .then((data) => {
           console.log("File Upload", newFile, data);
           const fileData = {
             uid: file.uid,
             name: name,
             status: "done",
             url: data.location,
             thumbUrl: data.location,
           };
           setFile([fileData]);
           if (onChange) {
             onChange([fileData]);
           }
         })
         .catch((err) => console.error(err));*/
  };

  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      console.log("Image Upload", img.width, img.height);
      img.onload = () => {
        if (img.width === 720 && img.height === 480) {
          resolve();
        } else {
          reject(
            `Please upload an image with dimensions 720 X 480. uploaded image is ${img.width} X ${img.height}`,
          );
        }
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const beforeUpload = async (file) => {
    try {
      if (fileType.includes(file.type)) {
      } else {
        ShowToast("File format is not correct", Severty.ERROR);
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < size;

      if (!isLt2M) {
        ShowToast(`Image must be smaller than ${size} MB!`, Severty.ERROR);
        return false;
      }

      isDimension && (await checkImageDimensions(file));

      return true;
    } catch (err) {
      ShowToast(err, Severty.ERROR);
      return false;
    }
  };

  const Delete = (filename) => {

    console.log(filename, "filename", imageType);
    const fileName = filename.split('/')
    console.log(fileName[fileName.length - 1], "filename[fileName.length-1]");
    deleteFile(fileName[fileName.length - 1], s3Config(imageType))
      .then(response => console.log(response))
      .catch(err => console.error(err))

  }

  const onRemove = (data) => {
    console.log(data, "Remove", file);
    // Delete(value,"")
    setFile([]);
    if (onChange) {
      onChange([]);
    }
  };

  useEffect(() => {
    if (!value) setFile([]);
  }, [value]);

  return (
    <Upload
      listType="picture"
      maxCount={1}
      beforeUpload={beforeUpload}
      customRequest={handleImgChange}
      onRemove={onRemove}
      fileList={file}
      {...props}
    >
      {file && file.length > 0 && file !== "" ? null : (
        <Button icon={<UploadOutlined />}>
          {" "}
          {btnName ? `${lang('Upload')} ${btnName}` : ""}
        </Button>
      )}
    </Upload>
  );
};

export default SingleImageUpload;
