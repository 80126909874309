import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
  TimePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";

import EditIcon from "../../assets/images/icon/edit-icon.png";
import starLight from "../../assets/images/icon/starLight.png";
import notfound from "../../assets/images/not_found.png";
import MultipleImageUpload from "../../components/MultipleImageUpload";
import SingleImageUpload from "../../components/SingleImageUpload";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../../pages/User/LocationMap";
import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import UploadImage from "../../pages/User/Customer/_UploadImage";
import { Palestine } from "../../constants/conts";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";
import checkNumber from "../../helper/apis"
const weekdays = [
  { name: "sunday", label: "S" },
  { name: "monday", label: "M" },
  { name: "tuesday", label: "T" },
  { name: "wednesday", label: "W" },
  { name: "thursday", label: "Th" },
  { name: "friday", label: "F" },
  { name: "saturday", label: "ST" },
];

const FileType = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/avif",
  "image/webp",
  "image/gif",
];

const ViewAndEditProfile = ({
  section,
  show,
  hide,
  data: vendor,
  refresh,
  deleteRestaurant,
}) => {
  const api = {
    updateProfile: apiPath.updateProfile,
    restaurant: apiPath.restaurant,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    fc: apiPath.common.foodCategories,
    rc: apiPath.common.restaurantCategories,
  };
  const { language } = useAppContext()

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();
  const [cities, setCities] = useState([]);
  const [document, setDocument] = useState();
  const [areas, setAreas] = useState([]);
  const [logo, setLogo] = useState();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumber2, setMobileNumber2] = useState({});
  const [location, setLocation] = useState(null);
  const [editLocation, setEditLocation] = useState(null);

  //for edit
  const [deleteModal, showDeleteModal] = useState(false);
  const showModal = () => {
    showDeleteModal(true);
  };

  const handleImage = (value) => {
    setImage((prev) => {
      return value.map((item) => {
        return item.url;
      });
    });
  };

  const handleCountryChange = (e) => {
    getCities(e);
    getCategory(e);
  };

  const getCategory = (country_id) => {
    request({
      url: api.rc + `?country_id=${country_id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setRestaurantCategories(data); // TODO: change dummy
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getAreas = (id) => {
    console.log(id, "djkghjkhfgjhfjghfjghfjhgjfhgjfh");
    request({
      url: `/city-area/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setAreas(data);
        }
      },
    });
  };

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities(data);
        }
      },
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    const restaurant = vendor.restaurant_id;
    if (!restaurant) return;
    const { location, mobile_number_sec, auto_accept_order, ...rest } =
      restaurant;
    form.setFieldsValue({
      ...rest,
      close_time: moment(rest.close_time),
      open_time: moment(rest.open_time),
      time: [moment(rest.open_time), moment(rest.close_time)],
      mobile: rest.country_code + rest.mobile_number,
      mobile2: vendor.country_code_sec
        ? vendor.country_code_sec + vendor.mobile_number_sec
        : "",
      country_id: rest?.country_id?._id ?? rest.country_id,
      city_id: rest?.city_id?._id ?? rest?.city_id,
      area: rest?.area?._id ?? rest?.area,
      category_id: rest?.category_id?._id ?? rest.category_id,
      location_on_map: rest.address,
      have_whatsapp: vendor?.have_whatsapp,
      have_whatsapp_sec: vendor?.have_whatsapp_sec,
      auto_accept_order: auto_accept_order,
    });
    console.log("auto_accept_order", auto_accept_order);
    getCities(restaurant?.country_id);
    setCountry(restaurant?.country_id);
    getAreas(rest?.city_id);
    setLogo(rest.logo);
    setImage(rest.image);
    setLocation({
      map_address: restaurant.map_address,
      latitude: rest.latitude,
      longitude: rest.longitude,
    });
    setEditLocation({
      map_address: rest.map_address,
      latitude: rest.latitude,
      longitude: rest.longitude,
    });
    getCategory(rest?.country_id);
    setMobileNumber({
      country_code: rest.country_code,
      mobile_number: rest.mobile_number,
    });
    setMobileNumber2({
      country_code_sec: vendor.country_code_sec,
      mobile_number_sec: vendor.mobile_number_sec,
    });

    setDocument(rest.document);
  }, [vendor]);

  const onCreate = async (values) => {
    setLoading(true);
    // const valid = await checkNumber({ number: mobileNumber.country_code + mobileNumber.mobile_number })
    // if (!valid) return setLoading(false);

    // if(mobileNumber2?.mobile_number_sec?.length >4){
    //   const valid = await checkNumber({ number: mobileNumber2.country_code_sec + mobileNumber2.mobile_number_sec })
    // if (!valid) return setLoading(false);
    // }

    const checks = [
      checkNumber({ number: mobileNumber.country_code + mobileNumber.mobile_number })
    ];
 
    if (mobileNumber2?.mobile_number_sec?.length > 4) {
      checks.push(checkNumber({ number: mobileNumber2.country_code_sec + mobileNumber2.mobile_number_sec }));
    }
  
    const results = await Promise.all(checks);

    if (results.some(valid => !valid)) {
      return setLoading(false);
    }

    const { location_on_map, time } = values;
  
    let payload = {
      ...values,
      ...mobileNumber,
      open_time: time[0],
      close_time: time[1],
      ...mobileNumber2,
      ...location,
      image: image,
    };

    if (logo) payload.logo = logo;
    if (document) payload.document = document;

    console.log(payload, "payload");
    request({
      url: api.updateProfile,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleUploadLegal = (data) => {
    setDocument(data[0]?.url);
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };
  const handleMobileNumberChange2 = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber2({
      country_code_sec: country_code,
      mobile_number_sec: value.slice(data?.dialCode?.length),
    });
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const dummyDeliveryTypes = [
    { name: "Delivery", ar_name: "توصيل", _id: "Delivery" },
    { name: "Pickup", ar_name: lang("Pickup"), _id: "Pickup" },
  ];


  const handleLocationChange = (value) => {
    setLocation({
      map_address: value.map_address,
      latitude: value.lat ?? value.latitude,
      longitude: value.lng ?? value.longitude,
    });
  };

  const handleTimeChange = (value) => {
    // Format the time as HH:mm when the value is not null
    if (value !== null) {
      const hours = Math.floor(value / 60);
      const minutes = value % 60;
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      // Set the formatted time back to the form field
      form.setFieldsValue({ approx_delivery_time: formattedTime });
    }
  };

  const handleTimeBlur = () => {
    // Validate the time format onBlur
    form.validateFields(["approx_delivery_time"]);
  };

  return (
    <Modal
      open={show}
      width={1400}
      okText="Save"
      footer={null}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <Form
        initialValues={{
          have_whatsapp_sec: false,
          have_whatsapp: false,
          // country_id: country.country_id,
          tax: 10,
          commission_rate: 10,
          online_commission_rate: 23,
        }}
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        className="row-col"
      >
        <Row gutter={[16, 16]}>
          <Col sm={24}>
            <Row gutter={[16, 16]}>
              <Col span={24} lg={24} xs={24}>
                <div className="row mb-3">
                  <div className="col-6">
                    <h4 className="modal_title_cls mb-2  modal_title_menu">
                      {/* {vendor.name} */}
                      {language !== "en" && language !== null
                        ? vendor?.[`${language}_name`] ??
                        vendor.name
                        : vendor.name}
                    </h4>
                    <div className="restaurantReview d-flex align-items-start gap-2  ">
                      <div className="d-flex align-items-center gap-2">
                        <img src={starLight} />
                        <img src={starLight} />
                        <img src={starLight} />
                        <img src={starLight} />
                        <img src={starLight} />
                      </div>
                      <div className="reviewTxt">
                        <b>0</b> (0 {lang("Reviews")})
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-flex align-items-center gap-3 justify-content-end">
                    {!isEdit && (
                      <Button
                        onClick={() => setIsEdit(true)}
                        className="edit-cls btnStyle primary_btn   d-flex align-items-center "
                      >
                        <img src={EditIcon} />
                        {lang("Edit")}
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col
                span={24}
                md={8}
                lg={8}
                xl={4}
                sm={8}
                xs={24}
                className="mb-2"
              >
                <Card className="" style={{ height: "100%" }}>
                  <div className="row g-3">
                    <Col span={24}>
                      <Form.Item
                        className="upload_wrap"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (logo) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(lang("Profile image is required")),
                              );
                            },
                          },
                        ]}
                        name="logo"
                      >
                        <UploadImage
                          disabled={!isEdit}
                          value={logo}
                          setImage={setLogo}
                        />
                      </Form.Item>
                    </Col>
                  </div>
                </Card>
              </Col>

              <Col
                span={24}
                md={16}
                lg={16}
                xl={10}
                sm={16}
                xs={24}
                className=" "
              >
                <Card className="" style={{ height: "100%" }}>
                  <div className="row g-3">
                    <Col span={24}>
                      <Form.Item
                        className="upload_wrap"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value !== undefined && value?.length > 0) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Profile image is required"),
                              );
                            },
                          },
                        ]}
                        label={lang("Upload Image")}
                        name="image"
                      >
                        <MultipleImageUpload
                          disabled={!isEdit}
                          value={image}
                          data={image}
                          fileType={FileType}
                          imageType={"category"}
                          btnName={lang("Image")}
                          onChange={(data) => handleImage(data)}
                          isDimension={true}
                        />
                      </Form.Item>
                      {/* {image && image.length > 0 && (
                        <div className="mt-2">
                          <Image
                            width={120}
                            src={image !== "" ? image : notfound}
                          ></Image>
                        </div>
                      )} */}
                    </Col>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col span={24} lg={14} xs={24} className="d-flex flex-column gap-3">
            <Card>
              <Row gutter={24}>
                {/* <SelectInput
                  name="country_id"
                  label="Country"
                  placeholder="Select country"
                  options={countries}
                  disabled={!isEdit}
                  handleChange={(e) => {
                    handleCountryChange(e);
                  }}
                  cover={{ md: 5 }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  rules={[
                    {
                      required: true,
                      message: "Missing Type Selection",
                    },
                  ]}
                />
                */}
                <SelectInput
                  name="city_id"
                  label={lang("City")}
                  disabled={true}
                  placeholder={lang("Select City")}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  handleChange={getAreas}
                  options={cities}
                  cover={{ md: 5 }}
                  rules={[
                    {
                      required: true,
                      message: lang("Missing Type Selection"),
                    },
                  ]}
                />

                {Palestine.id == country && (
                  <SelectInput
                    name="area"
                    disabled={true}
                    label={lang("Area")}
                    placeholder={lang("Select Area")}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={areas}
                    cover={{ md: 12 }}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Type Selection"),
                      },
                    ]}
                  />
                )}
              </Row>
              <Row gutter={20}>
                <TextInputBox
                  name="address"
                  isDisable={!isEdit}
                  label={lang("Address")}
                  placeholder={lang("Enter Address")}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Name should contain more then 600 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Name"),
                    },
                  ]}
                />
                <TextInputBox
                  name="ar_address"
                  isDisable={!isEdit}
                  label={lang("Address Arabic")}
                  placeholder={lang("Enter Address")}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Name should contain more then 600 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Name"),
                    },
                  ]}
                />
              </Row>
            </Card>
            <Card className="">
              <div className="row g-3">
                <SelectInput
                  cover={{ md: 24 }}
                  className="w-100 restaurant-selected"
                  mode="multiple"
                  name="category_id"
                  label={lang("Restaurant Category")}
                  placeholder={lang("Select Category")}
                  options={restaurantCategories}
                  rules={[
                    {
                      required: true,
                      message: lang("Missing Type Selection"),
                    },
                  ]}
                />
                <TextInputBox
                  isDisable={!isEdit}
                  name="name"
                  label={lang("Restaurant’s Name")}
                  placeholder={lang("Enter Restaurant Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Name"),
                    },
                  ]}
                />

                <TextInputBox
                  isDisable={!isEdit}
                  name="ar_name"
                  label={lang("Restaurant’s Name Arabic")}
                  placeholder={lang("Enter Restaurant Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Name"),
                    },
                  ]}
                />
                <TextInputBox
                  isDisable={!isEdit}
                  name="description"
                  label={lang("Restaurant Description")}
                  placeholder={lang("Enter Restaurant Description")}
                  rules={[
                    {
                      max: 500,
                      message: lang(
                        "Description should contain more then 500 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Description"),
                    },
                  ]}
                />
                <TextInputBox
                  isDisable={!isEdit}
                  name="ar_description"
                  label={lang("Restaurant Description Arabic")}
                  placeholder={lang("Enter Restaurant Description")}
                  rules={[
                    {
                      max: 500,
                      message: lang(
                        "Description should contain more then 500 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Description"),
                    },
                  ]}
                />
                <div className="col-xxl-6 col-lg-12 col-md-6">
                  <Form.Item
                    className="checkBox_wrap"
                    label={lang("Working Days")}
                    name="working_days"
                  >
                    <Checkbox.Group
                      disabled={!isEdit}
                      onChange={(value) => console.log(value, "working")}
                    >
                      {weekdays.map((item, idx) => (
                        <Checkbox value={item.name} key={idx}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
                <div className="col-xxl-6 col-lg-12 col-md-6 px-0">
                  <Row gutter={20}>
                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Working Time")}
                        name="time"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select working time"),
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          disabled={!isEdit}
                          format="h:mm a"
                          placeholder={[lang("Open Time"), lang("Close Time")]}
                          use12Hours
                          order={false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>

            <Row gutter={20}>
              <Col md={12}>
                <Card>
                  <Row gutter={[0, 16]} className="g-3">
                    <TextInputBox
                      isDisable={!isEdit}
                      name="contact_person_name"
                      label={lang("Contact Person Name")}
                      placeholder={lang("Enter Contact Person Name")}
                      cover={{ md: 24 }}
                      rules={[
                        {
                          max: 20,
                          message: lang(
                            "Name should contain more then 20 characters!",
                          ),
                        },
                        {
                          required: true,
                          message: lang("Please Enter Restaurant Name"),
                        },
                      ]}
                    />

                    <Col sm={24}>
                      <Form.Item
                        label={lang("Email ID")}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: lang(
                              "Please enter a valid email address!",
                            ),
                          },
                          {
                            max: 50,
                            message: lang(
                              "Email address not more then 255 characters!",
                            ),
                          },
                          {
                            required: true,
                            message: lang("Please enter email!"),
                          },
                        ]}
                      >
                        <Input
                          disabled={!isEdit}
                          placeholder={lang("Enter Email ID")}
                        />
                      </Form.Item>
                    </Col>

                    <PhoneNumberField
                      inputProps={{
                        disabled: !isEdit,
                      }}
                      label={lang("Contact Person Phone Number")}
                      name="mobile"
                      placeholder={lang("Enter Phone Number")}
                      cover={{ md: 24 }}
                      className=""
                      onChange={handleMobileNumberChange}
                    />

                    <Form.Item name="have_whatsapp" valuePropName="checked">
                      <Checkbox className="">
                        {lang("This number have WhatsApp to receive messages?")}
                      </Checkbox>
                    </Form.Item>

                    <PhoneNumberField
                      label={lang("Contact Person Phone Number")}
                      inputProps={{
                        disabled: !isEdit,
                      }}
                      name="mobile2"
                      placeholder={lang("Enter Phone Number")}
                      cover={{ md: 24 }}
                      className=""
                      onChange={handleMobileNumberChange2}
                    // rules={false}
                    />

                    <Form.Item name="have_whatsapp_sec" valuePropName="checked">
                      <Checkbox className="">
                        {lang("This number have WhatsApp to receive messages?")}
                      </Checkbox>
                    </Form.Item>
                  </Row>
                </Card>
              </Col>
              <Col md={12}>
                <Card>
                  <Row gutter={[16, 16]} className="g-3">
                    <TextInputBox
                      isDisable={!isEdit}
                      name="business_id"
                      label={lang("Business Identification Number")}
                      placeholder={lang("Enter Business Identification Number")}
                      cover={{ md: 24 }}
                      rules={[
                        // {
                        //     max: 16,
                        //     message: "Please Enter 16 digit BIN!",
                        // },
                        {
                          required: true,
                          message: lang("Enter Business Identification Number"),
                        },
                      ]}
                    />
                    <div className="col-12">
                      <Form.Item
                        className="mb-0"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (
                                document !== undefined &&
                                document?.length > 0
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(lang("legal documents is required")),
                              );
                            },
                          },
                        ]}
                        disabled={!isEdit}
                        label={lang("Upload legal documents")}
                        name="document1"
                        placeholder={lang("Upload Logo")}
                      >
                        <SingleImageUpload
                          disabled={!isEdit}
                          value={document}
                          fileType={FileType}
                          imageType={"legal"}
                          onChange={(data) => handleUploadLegal(data)}
                        />
                        {document && document.length > 0 && (
                          <div className="mt-2">
                            <Image
                              width={120}
                              src={document !== "" ? document : notfound}
                            ></Image>
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={10} xs={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <SelectInput
                  disabled={!isEdit}
                  className="restaurant-selected"
                  mode="multiple"
                  name="delivery_type"
                  label={lang("Delivery Type")}
                  placeholder={lang("Select Delivery Type")}
                  options={dummyDeliveryTypes}
                  cover={{ md: 12 }}
                  rules={[
                    { required: true, message: lang("Missing Type Selection") },
                  ]}
                />
                <Col md={12}>
                  <Form.Item
                    name="min_order_price"
                    label={lang("Min. Order Price")}
                    placeholder={lang("Enter Min. Order Price")}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Min. Order Price"),
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={!isEdit}
                      placeholder={lang("Enter Min. Order Price")}
                    />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    name="approx_delivery_time"
                    label={lang("Approximate preparation time(HH:MM)")}
                    rules={[
                      {
                        required: true,
                        message: lang("Enter preparation time"),
                      },
                      {
                        validator: (_, value) => {
                          if (/^\d{2}:\d{2}$/.test(value) || value === "") {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              lang("Enter delivery time in HH:MM format"),
                            ),
                          );
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={!isEdit}
                      placeholder="00:30 mins"
                      formatter={(value) => (value ? `${value}` : "")}
                      parser={(value) => (value ? value.replace(":", "") : "")}
                      onChange={handleTimeChange}
                      onBlur={handleTimeBlur}
                    />
                  </Form.Item>
                </Col>

                <Col md={10}>
                  <Form.Item
                    name="tax"
                    label={lang("Store Tax %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Store Tax Percentage"),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      min={0}
                      disabled={!isEdit}
                      max={100}
                      placeholder={lang("Enter Tax %")}
                    />
                  </Form.Item>
                </Col>

                <Col md={10}>
                  <Form.Item
                    name="commission_rate"
                    label={lang("Cash Commission Rate %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Cash Commission Rate"),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      max={100}
                      placeholder="10 %"
                      disabled={true}
                      min={0}
                    />
                  </Form.Item>
                </Col>

                <Col md={10}>
                  <Form.Item
                    name="online_commission_rate"
                    label={lang("Online Commission Rate %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Online Commission Rate"),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      max={100}
                      placeholder="10 %"
                      disabled={true}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={lang("Auto Accept Orders")}
                    name="auto_accept_order"
                    className="auto_accept_order oreder-bar-check"
                    valuePropName="checked"
                  >
                    <Switch disabled={!isEdit} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Row className="">
              <Col md={24} className="mt-5">
                <Form.Item
                  disabled={!isEdit}
                  label={lang("Location (Drag Marker for Selecting Location)")}
                  name="location_on_map"
                  rules={[
                    {
                      required: true,
                      message: lang("Please select the location!"),
                    },
                  ]}
                >
                  <LocationMap
                    className="mt-3"
                    onChange={handleLocationChange}
                    userData={location}
                    editLocation={editLocation}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 justify-content-end mt-5">
              {isEdit ? (
                <>
                  <Button
                    onClick={hide}
                    className=" btnStyle btnOutlineDelete "
                  >
                    {lang("Cancel")}
                  </Button>
                  <Button className="primary_btn btnStyle " htmlType="submit">
                    {lang("SUBMIT")}
                  </Button>
                </>
              ) : (
                <Button onClick={hide} className="primary_btn btnStyle ">
                  {lang("Okay")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ViewAndEditProfile;
