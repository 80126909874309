import { Col, Form, InputNumber, Modal, Row,Input } from "antd";
import React, { useState } from "react";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";

const FileType = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/avif",
  "image/webp",
  "image/gif",
];

const WithdrawModal = ({ show, hide, amount,reason }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  // const {id} = GetWallet();

  const onCreate = (values) => {
    const { amount, walletId,reason } = values;
    console.log(values, "values");

    let payload = {
      walletId:walletId,
      amount:amount,
      reason
    };
    setLoading(true);
    console.log(payload, "payload");
    request({
      url: 'vendor/wallet-withdraw',
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={800}
      okText={lang("Withdraw")}
      cancelText={lang("Cancel")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <h4 className="modal_title_cls">{lang("Withdraw")}</h4>
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              className="mb-0"
              label={lang("Withdraw Amount")}
              name="amount"
              initialValue={amount}
              rules={[
                {
                  required: true,
                  message: lang("Withdraw Amount is required!"),
                },
              ]}
            >
              <InputNumber autoComplete="off" placeholder={lang("Withdraw Amount")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className="mb-0"
              label={lang("Reason")}
              name="reason"
              // initialValue={reason}
              rules={[
                {
                  required: true,
                  message: lang("Reason is required!"),
                },
              ]}
            >
              <Input autoComplete="off" placeholder={lang("Reason")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default WithdrawModal;
