import * as XLSX from "xlsx";
import moment from "moment";

/* 
data = {order :{data,filename} , discount : {data,filename} , request:{data,filename}}

*/
const ExcelDownload = ({ data }) => {
    const { order, discount, request } = data
    const filename = "Invoice_" + " Excel Data, Export on " + moment().format("DD-MM-YYYY");

    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(order.data);
    // XLSX.utils.sheet_add_aoa(worksheet, [[mainHeading]], { origin: 'A1' });
    XLSX.utils.book_append_sheet(workbook, worksheet, order.filename);

    //SECOND 

    const additionalSheet = XLSX.utils.json_to_sheet(discount.data);
    //   XLSX.utils.sheet_add_aoa(additionalSheet, [["Additional Sheet Data"]], { origin: 'A1' });
    XLSX.utils.book_append_sheet(workbook, additionalSheet, discount.filename);

    //LAST 
    const requestSheet = XLSX.utils.json_to_sheet(request.data);
    //   XLSX.utils.sheet_add_aoa(additionalSheet, [["Additional Sheet Data"]], { origin: 'A1' });
    XLSX.utils.book_append_sheet(workbook, requestSheet, request.filename);


    XLSX.writeFile(
        workbook,
        `${moment().milliseconds() +
        1000 * (moment().seconds() + 60 * 60) +
        `-${filename}`
        }.xlsx`,
    );
    return true;
};

export default ExcelDownload